<div mat-dialog-title>
  <h2>{{ title | translation }}</h2>
  <button *ngIf="!config.hideClose" mat-dialog-close mat-icon-button>
    <mat-icon svgIcon="ak-close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="notification-window" *ngIf="config">
    <p class="content">{{ config.message }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-action-bar (clicked)="buttonClicked.emit($event)" [buttons]="config.buttons"></app-action-bar>
</mat-dialog-actions>
