import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfigOption, FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import * as fromTypes from './types';
import * as fromComponents from './components';
import * as fromWrappers from './wrapper';
import { CUSTOM_FORMLY_CONFIG } from './config';
import { registerValidationMessagesExtension } from './extensions/validation-messages.extension';
import { TranslationService } from '../../../core/services/translation.service';
import { SharedCoreModule } from '../../core/shared-core.module';
import { FileDropzoneModule, InputFileModule } from '@akelius/angular-ui-kit';
import { TreeModule } from '../tree/tree.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    CommonModule,
    SharedCoreModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    NativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatMomentDateModule,
    InputFileModule,
    FileDropzoneModule,
    FormlyModule,
    ScrollingModule,
    TreeModule,
  ],
  declarations: [...fromTypes.components, ...fromWrappers.components, ...fromComponents.components],
  exports: [FormlyModule, ...fromComponents.components],
})
export class UiFormlyModule {
  public static forRoot(): [ModuleWithProviders<UiFormlyModule>, ModuleWithProviders<FormlyModule>] {
    return [
      {
        ngModule: UiFormlyModule,
        providers: [
          {
            provide: FORMLY_CONFIG,
            multi: true,
            useFactory: registerValidationMessagesExtension, // FIXME make immutable default
            deps: [TranslationService],
          },
        ],
      },
      FormlyModule.forRoot(CUSTOM_FORMLY_CONFIG),
    ];
  }

  public static forChild(
    config: ConfigOption = {}
  ): [ModuleWithProviders<UiFormlyModule>, ModuleWithProviders<FormlyModule>] {
    return [
      {
        ngModule: UiFormlyModule,
        providers: [],
      },
      FormlyModule.forChild(config),
    ];
  }
}
