import {
  AssetTreeNode,
  AssetTreeNodeResponse,
  Data,
  MinimalAssetTreeNode,
  TreeAssetMapping,
} from '../models/interfaces';
import { TreeNode } from '../../../shared/functional/tree/models';
import { TranslationNamespace } from '../../../core/models/translation.variables';

export abstract class AssetTreeHelpers {
  static convertData(data: AssetTreeNode, includeInactive = false): TreeNode<MinimalAssetTreeNode, Data> {
    const { objectId: id } = data;
    const groups = AssetTreeHelpers.buildGroups(data, includeInactive);

    // The node data is stored at each level in the tree. This can become a lot of data and we don't use much of it
    // in order to save memory, we only store a minimal version
    const minimalAssetTreeNode: MinimalAssetTreeNode = {
      objectId: data.objectId,
      displayName: data.displayName,
      inactive: data.inactive,
      type: data.type,
      parentObjectIds: data.parentObjectIds,
    };

    return { id, data: minimalAssetTreeNode, isGroup: false, children: groups } as TreeNode<MinimalAssetTreeNode, Data>;
  }

  private static buildGroups(data: AssetTreeNode, includeInactive: boolean) {
    return data._children
      .map(
        (groupName: string) =>
          ({
            id: `${data.objectId}-${groupName}`,
            children: data[groupName]
              ? (<AssetTreeNode[]>data[groupName])
                  .filter((child) => includeInactive || !child.inactive)
                  .map((child) => AssetTreeHelpers.convertData(child, includeInactive))
              : [],
            isGroup: true,
            data: {
              label:
                data[groupName] && (data[groupName] as AssetTreeNode[])[0]?._ui?.typePluralDictionaryKey
                  ? TranslationNamespace.procaUiConfig +
                    (data[groupName] as AssetTreeNode[])[0]._ui.typePluralDictionaryKey
                  : groupName,
              type:
                data[groupName] && (data[groupName] as AssetTreeNode[])[0]?.type
                  ? (data[groupName] as AssetTreeNode[])[0]?.type
                  : '', // property-type-icon.pipe will show default icon if type does not exist
            },
          }) as TreeNode<MinimalAssetTreeNode, Data>,
      )
      .filter((group) => group?.children && group.children?.length > 0); // Don't show groups with no children
  }

  // Adds parent IDs to the objects and creates a mapping from objectID to AssetTreeNode
  static prepareTree(treeRootNode: AssetTreeNodeResponse): [AssetTreeNode, TreeAssetMapping] {
    const treeAssetMapping: TreeAssetMapping = {};

    const nodeProcessor = (inputNode: AssetTreeNodeResponse, parentIds: string[]): AssetTreeNode => {
      const inputNodeWithParentIds: AssetTreeNode = {
        ...inputNode,
        parentObjectIds: parentIds,
      };

      treeAssetMapping[inputNode.objectId] = inputNodeWithParentIds;

      const updatedChildrenNodes = inputNode._children.reduce(
        (acc: { [childPropertyName: string]: AssetTreeNode[] }, childPropertyKey) => {
          const childNodes = inputNode[childPropertyKey] as AssetTreeNode[];

          acc[childPropertyKey] = childNodes.reduce((nodes: AssetTreeNode[], node) => {
            const preparedNode = nodeProcessor(node, [...parentIds, inputNode.objectId]);
            return [...nodes, preparedNode];
          }, []);

          return acc;
        },
        {},
      );

      return { ...inputNodeWithParentIds, ...updatedChildrenNodes };
    };

    const result = nodeProcessor(treeRootNode, []);

    return [result, treeAssetMapping];
  }
}
