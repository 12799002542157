import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'formly-file-specific-repeat',
  styleUrls: ['./file-upload-stepper-type.component.scss'],
  template: `
    <mat-horizontal-stepper labelPosition="bottom" linear [@.disabled]="true">
      <mat-step
        *ngFor="let step of field.fieldGroup; let index = index; let last = last; trackBy: identify"
        [stepControl]="step.formControl"
      >
        <ng-template matStepLabel> {{ step.props.label | translation }}</ng-template>
        <formly-field [field]="step"></formly-field>
        <div>
          <div class="action-bar">
            <button
              *ngIf="index !== 0"
              type="button"
              matStepperPrevious
              mat-flat-button
              data-test-back-button
              (click)="previousStep()"
            >
              {{ 'core.button.back' | translation }}
            </button>

            <button
              *ngIf="!last"
              type="button"
              matStepperNext
              mat-flat-button
              data-test-next-button
              (click)="nextStep()"
              [disabled]="step.formControl.invalid"
            >
              {{ 'core.button.next' | translation }}
            </button>

            <button
              *ngIf="last"
              class="btn btn-primary"
              mat-flat-button
              data-test-submit-button
              (click)="submit()"
              [disabled]="form.invalid"
              [color]="'primary'"
            >
              {{ 'core.button.submit' | translation }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFileUploadStepperComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  currentStep$ = new BehaviorSubject(0);
  defaultOptions = { defaultValue: {} };

  identify(index: number, step: FormlyFieldConfig) {
    return step.key;
  }

  submit() {
    this.field?.props?.submit();
  }

  previousStep() {
    this.currentStep$.next(this.currentStep$.value - 1);
  }

  nextStep() {
    this.currentStep$.next(this.currentStep$.value + 1);
  }

  public ngOnDestroy(): void {}
}
