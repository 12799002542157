<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput #searchTextEl [matAutocomplete]="auto" [formControl]="searchText" />

  <div class="suffix-area" matSuffix>
    <mat-spinner *ngIf="loading" [diameter]="18" [strokeWidth]="3"></mat-spinner>
    <button *ngIf="searchTextEl.value" mat-icon-button (click)="clear()">
      <mat-icon svgIcon="ak-close"></mat-icon>
    </button>
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelect($event.option.value)"
    [displayWith]="getDisplayValue"
  >
    <mat-option *ngFor="let item of items" [value]="item" [innerHTML]="item.label"></mat-option>
    <mat-option
      *ngIf="!items?.length"
      disabled
      [innerHTML]="'dynamic.node.filter.group.noData' | translation"
    ></mat-option>
  </mat-autocomplete>
</mat-form-field>
