import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-10-10T13:45:38+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '80e7078d584616ea59b40a17e26cbef30dca8d2e',
  },
};
