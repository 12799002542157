<form [formGroup]="form">
  <app-tree
    #treeComponent
    [parentNodeTemplate]="treeParentNodeLabel"
    [leafNodeTemplate]="treeLeafNodeLabel"
    [treeDefinition]="treeDefinition"
  >
    <ng-template #treeLeafNodeLabel let-node>
      <mat-checkbox
        class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="leafItemSelectionToggle(node)"
      >
        <ng-container
          [ngTemplateOutlet]="leafNodeTemplate"
          [ngTemplateOutletContext]="{ $implicit: node }"
        ></ng-container>
      </mat-checkbox>
    </ng-template>

    <ng-template #treeParentNodeLabel let-node>
      <mat-checkbox
        class="tree-checkbox"
        [ngClass]="{ 'select-all': node.isGroup }"
        [checked]="isChecked(node)"
        [indeterminate]="(propagateSelection || node.isGroup) && descendantsPartiallySelected(node)"
        (change)="propagateSelection || !node.isGroup ? itemSelectionToggle(node) : groupSelectionToggle(node)"
      >
        <ng-container
          [ngTemplateOutlet]="parentNodeTemplate"
          [ngTemplateOutletContext]="{ $implicit: node }"
        ></ng-container>
      </mat-checkbox>
    </ng-template>
  </app-tree>
</form>
