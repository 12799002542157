import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { LightboxComponent, LightBoxConfig } from '../components/lightbox/lightbox.component';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  rootViewContainer: ViewContainerRef;
  private lightBoxComponentRef: ComponentRef<LightboxComponent> | null;
  private subscriptions: Subscription[] = [];

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  showLightBox(configData: LightBoxConfig) {
    this.lightBoxComponentRef ? this.updateLightBoxConfig(configData) : this.createLightBox(configData);
  }

  createLightBox(configData: LightBoxConfig) {
    this.lightBoxComponentRef = this.rootViewContainer.createComponent<LightboxComponent>(LightboxComponent);

    this.updateLightBoxConfig(configData);

    this.setupSubscriptions(configData);

    this.lightBoxComponentRef.changeDetectorRef.detectChanges();
  }

  updateLightBoxConfig(configData: LightBoxConfig) {
    if (this.lightBoxComponentRef?.instance) {
      this.lightBoxComponentRef.setInput('configData', configData);
    }
  }

  closeLightBox() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

    if (this.lightBoxComponentRef) {
      this.lightBoxComponentRef.destroy();
      this.lightBoxComponentRef = null;
    }
  }

  setupSubscriptions(configData: LightBoxConfig) {
    if (this.lightBoxComponentRef) {
      this.subscriptions.push(
        this.lightBoxComponentRef.instance.closeSelected.subscribe({
          next: () => {
            this.closeLightBox();
          },
        }),
      );

      if (configData.previousButtonHandler) {
        this.subscriptions.push(
          this.lightBoxComponentRef.instance.prevButtonClicked.subscribe(configData.previousButtonHandler),
        );
      }

      if (configData.nextButtonHandler) {
        this.subscriptions.push(
          this.lightBoxComponentRef.instance.nextButtonClicked.subscribe(configData.nextButtonHandler),
        );
      }

      if (configData.deleteImageHandler) {
        this.subscriptions.push(
          this.lightBoxComponentRef.instance.deleteImageEvent.subscribe(configData.deleteImageHandler),
        );
      }

      if (configData.editImageHandler) {
        this.subscriptions.push(
          this.lightBoxComponentRef.instance.editImageEvent.subscribe(configData.editImageHandler),
        );
      }

      if (configData.saveImageHandler) {
        this.subscriptions.push(
          this.lightBoxComponentRef.instance.saveImageEvent.subscribe(configData.saveImageHandler),
        );
      }
    }
  }
}
