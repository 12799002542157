import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { GlobalLoadingIndicatorService } from '../services/global-loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationGuard  {
  private errorHandlerService = inject(ErrorHandler);

  constructor(
    private router: Router,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private loadingIndicatorService: GlobalLoadingIndicatorService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.translationService.loadTranslations().pipe(
      catchError((error) => {
        this.router.navigateByUrl('/app-unavailable');
        this.errorHandlerService.handleError(error);
        return of(false);
      }),
      tap({
        next: (fallbackUsed) => {
          if (fallbackUsed) {
            this.notificationService.showErrorDialog('core.error.usingFallbackTranslations');
          }
        },
      }),
      map((_) => true),
      finalize(() => this.loadingIndicatorService.setLoading(false))
    );
  }
}
