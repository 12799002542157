export interface User {
  address: UserAddress;
  email: string;
  email_verified: boolean;
  id: string;
  family_name: string;
  given_name: string;
  'https://akelius.com/claims/email': string;
  'https://akelius.com/claims/family_name': string;
  'https://akelius.com/claims/given_name': string;
  'https://akelius.com/claims/userid': string;
  'https://akelius.com/identityroles': string[];
  'https://intranet.akelius.com/claims/roles': string[];
  'https://propertycatalog.akelius.com/claims/roles': string[];
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface UserAddress {
  country: string;
  locality: string;
  postal_code: string;
  region: string;
  street_address: string;
}

interface Roles {
  maintainer: string;
  debugger: string;
  employee: string;
  propertyExporter: string;
  companyDocumentsMaintainer: string;
  vocabularyEntryMaintainer: string;
}

export const ROLES: Roles = {
  maintainer: 'Maintainer',
  debugger: 'Debugger',
  employee: 'Employee',
  propertyExporter: 'Property-Exporter',
  companyDocumentsMaintainer: 'Company-Documents-Maintainer-',
  vocabularyEntryMaintainer: 'Vocabulary-Entry-Maintainer',
};
