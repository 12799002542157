<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    matTreeNodePadding
    matTreeNodePaddingIndent="16"
    rippleHover
    [rippleHoverDisabled]="isGroup(node)"
    [ngClass]="{ 'selected-node': isSelectedNode(node), 'no-group-node': !isGroup(node) }"
  >
    <div class="button-placeholder"></div>
    <span class="content-wrapper">
      <ng-container
        [ngTemplateOutlet]="leafNodeTemplate"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
    </span>
  </mat-tree-node>

  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding
    matTreeNodePaddingIndent="16"
    rippleHover
    [rippleHoverDisabled]="isGroup(node)"
    [ngClass]="{ 'selected-node': isSelectedNode(node), 'no-group-node': !isGroup(node) }"
  >
    <button
      mat-icon-button
      matTreeNodeToggle
      [hidden]="!node.expandable"
      [attr.aria-label]="'toggle ' + node.filename"
      class="margin-left"
    >
      <mat-icon svgIcon="{{ treeControl.isExpanded(node) ? 'ak-chevron-down' : 'ak-chevron-right' }}"></mat-icon>
    </button>
    <span class="content-wrapper">
      <ng-container
        [ngTemplateOutlet]="parentNodeTemplate"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
    </span>
  </mat-tree-node>
</mat-tree>
