import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { NgModule } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { EmptyError, lastValueFrom } from 'rxjs';

export function provideApollo(httpLink: HttpLink, appConfig: AppConfig, authService: AuthService) {
  const basic = setContext((_operation, _context) => {
    return {
      headers: {
        Accept: 'charset=utf-8',
      },
    };
  });

  const auth = setContext(async () => {
    let token = '';

    try {
      token = await lastValueFrom(authService.getTokenSilently$());
    } catch (err) {
      if (err instanceof EmptyError) {
        console.warn('source did not emit');
      }
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const uri = appConfig.staffApp.backend.gql;

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo,
      deps: [HttpLink, APP_CONFIG, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class GraphQLModule {}
