import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '../store.service';

import { ContextState } from './context.state';

export interface PreFillValues {
  [key: string]: any;
}

export const ContextInitialState: ContextState = {
  context: null,
  breadcrumbPath: null,
  loading: false,
  loaded: false,
  selectedEntity: null,
  preFillValues: {},
};

@Injectable({
  providedIn: 'root',
})
export class ContextStoreService extends Store<ContextState> implements OnDestroy {
  constructor() {
    super(ContextInitialState);
  }
}
