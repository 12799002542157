import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-document-control',
  templateUrl: './document-control.component.html',
  styleUrls: ['./document-control.component.scss'],
})
export class DocumentControlComponent {
  @Input() dataTestingId = '';

  @Input()
  notifiable = false;
  @Input() editable = false;
  @Input() deleteable = false;
  @Input() downloadable = false;
  @Input() hasDebuggerRole = false;
  @Input() shareable = false;

  @Output() notify = new EventEmitter<MouseEvent>();
  @Output() edit = new EventEmitter<MouseEvent>();
  @Output() delete = new EventEmitter<MouseEvent>();
  @Output() download = new EventEmitter<MouseEvent>();
  @Output() debug = new EventEmitter<MouseEvent>();
  @Output() share = new EventEmitter<MouseEvent>();

  notifyClicked(event: MouseEvent) {
    this.notify.emit(event);
  }

  editClicked(event: MouseEvent) {
    this.edit.emit(event);
  }

  deleteClicked(event: MouseEvent) {
    this.delete.emit(event);
  }

  downloadClicked(event: MouseEvent) {
    this.download.emit(event);
  }

  debugClicked(event: MouseEvent) {
    this.debug.emit(event);
  }

  shareClicked(event: MouseEvent) {
    this.share.emit(event);
  }
}
