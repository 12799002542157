import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue',
})
export class DefaultValuePipe implements PipeTransform {
  constructor() {}

  transform(value: any, defaultValue: string = '-'): string {
    return value ?? defaultValue;
  }
}
