export class NotFoundError extends Error {
  constructor(public originalError?: any) {
    super(originalError.message);
    this.name = NotFoundError.name;
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }

  toString() {
    return this.originalError.message;
  }
}
