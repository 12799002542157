import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appIcon',
})
export class AppIconPipe implements PipeTransform {
  transform(value: string): string {
    return value ? 'ak-appicon-' + value.toLowerCase() : '';
  }
}
