import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  ChildNodeData,
  ColumnsEntity,
  FieldsEntity,
  FilterGroupsEntity,
  FiltersEntity,
  LeafNode,
  TableView,
  UploadView,
} from '../models/node-data.interfaces';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class NodeService extends DataService {
  private API_PATH = `${this.appConfig.backend}nodes`;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) {
    super();
  }

  getNodesData(pathName: string): Observable<LeafNode> {
    return this.http.get<LeafNode>(`${this.API_PATH}${pathName}`).pipe(
      map((nodeData) => NodeService.mapNodeDataByContext(nodeData, pathName)),
      catchError(this.handleError)
    );
  }

  getLeafNodeById(nodeId: string): Observable<LeafNode> {
    return this.http.get<LeafNode>(this.API_PATH, { params: this.toHttpParams({ id: nodeId }) }).pipe(
      map((nodeData) => NodeService.mapNodeDataByContext(nodeData, nodeId)),
      catchError(this.handleError)
    );
  }

  getFilterGroups(
    pathName = '',
    selectedParameters?: { [name: string]: string }
  ): Observable<FilterGroupsEntity[] | null> {
    pathName = pathName.replace(/^\//, '');

    return this.http
      .get<FilterGroupsEntity[]>(`${this.appConfig.backend}filter-groups/`, {
        params: { path: pathName, ...selectedParameters },
      })
      .pipe(
        map((filterGroups: FilterGroupsEntity[]) => NodeService.addTranslationKeysToFilters(filterGroups)),
        catchError(this.handleError)
      );
  }

  private static mapNodeDataByContext(nodeData: LeafNode, pathName: string) {
    return {
      ...nodeData,
      path: pathName,
      translationKey: `dynamic.node.tile.${nodeData.name}`,
      filterGroups: nodeData.filterGroups ? NodeService.addTranslationKeysToFilters(nodeData.filterGroups) : null,
      children: nodeData.children ? NodeService.addTranslationKeysToChildNodes(nodeData.children) : null,
      tableView: nodeData.tableView ? NodeService.addTranslationKeysToTableView(nodeData.tableView) : null,
      uploadView: nodeData.uploadView ? NodeService.addTranslationKeysToUploadView(nodeData.uploadView) : null,
    };
  }

  private static addTranslationKeysToFilters(filterGroups: FilterGroupsEntity[]): FilterGroupsEntity[] {
    return filterGroups.reduce((acc: FilterGroupsEntity[], filterGroup: FilterGroupsEntity) => {
      acc.push({
        ...filterGroup,
        translationKey: `dynamic.node.filter.group.${filterGroup.name}`,
        filters: filterGroup.filters.map((filter) => ({
          ...filter,
          translationKey: `dynamic.node.filter.tag.${filter.name}`,
        })) as FiltersEntity[],
      });
      return acc;
    }, []);
  }

  private static addTranslationKeysToChildNodes(nodes: ChildNodeData[]): ChildNodeData[] {
    return nodes.map((child) => ({
      ...child,
      translationKey: `dynamic.node.tile.${child.name}`,
    }));
  }

  private static addTranslationKeysToTableView(tableView: TableView): TableView {
    return {
      ...tableView,
      columns: tableView?.columns?.map((column) => ({
        ...column,
        translationKey: column.title ? `dynamic.node.table.header.${column.title}` : null,
      })) as ColumnsEntity[],
    };
  }

  private static addTranslationKeysToUploadView(uploadView: UploadView): UploadView {
    return {
      ...uploadView,
      fields: uploadView.fields
        ? uploadView.fields.map((field) => ({
            ...field,
            translationKey: `dynamic.node.upload.field.${field.title}.label`,
          }))
        : null,
      fileSpecificFields: uploadView.fileSpecificFields
        ? (uploadView.fileSpecificFields.map((field) => ({
            ...field,
            translationKey: `dynamic.node.upload.field.${field.title}.label`,
          })) as FieldsEntity[])
        : null,
    };
  }
}
