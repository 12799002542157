import { Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { SentryService } from '../services/sentry.service';

@Injectable()
export class RequestIdInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig, private sentry: SentryService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestId = uuidv4().replace(/-/g, '');
    const { method, urlWithParams } = req;

    this.sentry.addBreadcrumb({
      data: {
        urlWithParams,
        method,
        requestId,
      },
    });

    const requestWithId =
      req.url.indexOf(this.appConfig.backend) >= 0
        ? req.clone({
            setHeaders: {
              'x-request-id': requestId,
            },
          })
        : req;
    return next.handle(requestWithId);
  }
}

export const RequestIdInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestIdInterceptor,
  multi: true,
};
