export class BadInputError extends Error {
  constructor(public originalError?: any) {
    super(originalError.message);
    this.name = BadInputError.name;
    Object.setPrototypeOf(this, BadInputError.prototype);
  }

  toString() {
    return this.originalError.message;
  }
}
