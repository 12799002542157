import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { NotificationService } from '../../../../core/services/notification.service';

@UntilDestroy()
@Component({
  selector: 'app-help-text-icon',
  templateUrl: './help-text-icon.component.html',
  styleUrls: ['./help-text-icon.component.scss'],
})
export class HelpTextIconComponent {
  @Input() declaredValue: string;
  @Input() helpTextKey: string;

  constructor(private notificationService: NotificationService) {}

  showHelpModal(event: MouseEvent) {
    event.stopPropagation();
    this.notificationService.showHelpTextDialog(this.helpTextKey, this.declaredValue);
  }
}
