import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AssetTabType } from '../../../../features/property-catalog/models/asset-tab.enum';
import { MatTabNavPanel } from '@angular/material/tabs';

export interface Tab {
  type: AssetTabType;
  titleTranslationKey: string;
  hidden?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-tab-nav-bar',
  templateUrl: './tab-nav-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavBarComponent {
  @Input() tabs: Tab[] = [];
  @Input() routerLinkExact = true;
  @Input() tabPanel: MatTabNavPanel;
}
