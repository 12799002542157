import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map } from 'rxjs/operators';

const DATE_RANGE_PICKER_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangePickerComponent),
  multi: true,
};

const DATE_FORMAT = 'YYYY-MM-DD';

const MY_FORMATS = {
  parse: {
    dateInput: DATE_FORMAT,
  },
  display: {
    dateInput: DATE_FORMAT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@UntilDestroy()
@Component({
  selector: 'forms-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  providers: [
    DATE_RANGE_PICKER_ACCESSOR,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit {
  @Input()
  set selectedValue(date: { begin: Moment; end: Moment }) {
    if (date) {
      this.form.patchValue(date, { emitEvent: false });
    }
  }

  dateValue: string | null = null;
  form = this.formBuilder.group({ begin: [], end: [] });

  onTouched: Function = () => {};
  propagateChange: Function = () => {};

  constructor(private formBuilder: UntypedFormBuilder) {}

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  writeValue(date: string | null) {
    this.dateValue = date ?? null;
    if (!date) {
      this.form.patchValue({ begin: null, end: null }, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this), debounceTime(100), map(DateRangePickerComponent.mapDateToValue.bind(this)))
      .subscribe({
        next: (dateRange: string | null) => {
          this.updateValue(dateRange);
        },
      });
  }

  private updateValue(date: string | null) {
    this.writeValue(date);
    this.onTouched();
    this.propagateChange(this.dateValue);
  }

  private static mapDateToValue({ begin, end }: { begin: Moment; end: Moment }): string | null {
    const startDate = DateRangePickerComponent.isValidMomentDate(begin) ? begin?.format(DATE_FORMAT) : null;
    const endDate = DateRangePickerComponent.isValidMomentDate(end) ? end.format(DATE_FORMAT) : null;

    return endDate ? `${startDate ?? ''}~~${endDate}` : startDate;
  }

  private static isValidMomentDate(date: Moment): date is Moment {
    return date?.isValid() && typeof date.format === 'function';
  }
}
