import { Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { TreeDatabase } from '../../services';
import { ItemFlatNode, ItemNode, TreeNode } from '../../models';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeDatabase],
})
export class TreeComponent<N, G> implements OnChanges {
  @Input() parentNodeTemplate: TemplateRef<any>;
  @Input() leafNodeTemplate: TemplateRef<any>;
  @Input() treeDefinition: TreeNode<N, G>;
  @Input() rootNodeIsGroup = false;
  @Input() selectedNodeId: string;

  treeControl: FlatTreeControl<ItemFlatNode<N, G>>;

  treeFlattener: MatTreeFlattener<ItemNode<N, G>, ItemFlatNode<N, G>>;

  dataSource: MatTreeFlatDataSource<ItemNode<N, G>, ItemFlatNode<N, G>>;

  flatNodeMap = new Map<ItemFlatNode<N, G>, ItemNode<N, G>>();

  nestedNodeMap = new Map<ItemNode<N, G>, ItemFlatNode<N, G>>();

  flatNodes: ItemFlatNode<N, G>[] = [];

  constructor(public treeDatabase: TreeDatabase<N, G>, public element: ElementRef) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode<N, G>>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    treeDatabase.dataChange$.subscribe({
      next: (data) => {
        this.dataSource.data = data;
        this.flatNodes = this.treeFlattener.flattenNodes(data);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['treeDefinition'] && changes['treeDefinition'].currentValue) {
      this.treeDatabase.initialize(this.treeDefinition);
    }
  }

  expandNode(assetId: string) {
    const flatNode = this.flatNodes.find((node) => node.id === assetId) as ItemFlatNode<N, G>;

    this.treeControl.expand(flatNode);
    const parentNode = this.getParentNode(flatNode);

    if (parentNode) {
      this.expandNode(parentNode.id);
    }

    // it is possible that there will be no single selected node (eg for a selection tree)
    // so we have to check if it is defined
    this?.element?.nativeElement
      ?.querySelector('.selected-node')
      ?.scrollIntoViewIfNeeded({ block: 'nearest', inline: 'nearest' });
  }

  expandAll() {
    this.treeControl.expandAll();
  }

  collapseAll() {
    this.treeControl.collapseAll();
  }

  getLevel = (node: ItemFlatNode<N, G>) => node.level;

  isExpandable = (node: ItemFlatNode<N, G>) => node.expandable;

  getChildren = (node: ItemNode<N, G>): ItemNode<N, G>[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode<N, G>) => _nodeData.expandable;

  isSelectedNode = (node: ItemFlatNode<N, G>) => node.id === this.selectedNodeId;

  isGroup = (node: ItemFlatNode<N, G>) => node.isGroup;

  transformer = (node: ItemNode<N, G>, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id ? existingNode : new ItemFlatNode<N, G>();
    const children = node.children && (Array.isArray(node.children) ? node.children : Object.values(node.children));

    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.data = node.data;
    flatNode.isGroup = node.isGroup;
    flatNode.parentIds = node.parentIds;
    flatNode.expandable = children && !!children.length;
    flatNode.childCount = node.children ? node.children.length : 0;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  };

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode<N, G>): ItemFlatNode<N, G> | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
