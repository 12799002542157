import { Inject, Injectable } from '@angular/core';
import {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  IdToken,
  User as Auth0User,
} from '@auth0/auth0-spa-js';
import { from, of, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, catchError, concatMap, map, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { jwtDecode } from 'jwt-decode';
import { GoogleAnalyticsHelpers } from '../helpers/google-analytics.helpers';
import { User } from '../models/auth.interfaces';
import { WINDOW_OBJECT } from '../core.module';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AkeliusIDToken } from '../models/akelius-id-token.model';
import { filterNull, filterNullish, intShareReplay } from '../../../support/rxjs-operators';
import { AuthClientInjectable } from '../injection-tokens/auth-client.token';

const tenantMap = new Map<string, string>([
  ['akelius-forest', 'org_NeuzulTjnT8AOs4J'],
  ['akelius-residential', 'org_BKXHzEkYuQsM7RYV'],
  ['skyline', 'org_kllHajzVJLB4gjAn'],
  ['stena', 'org_mSAehhhnWGOHMh29'],
  ['victoriahem', 'org_aRfmSYeh3iGDxiv0'],
  ['immo', 'org_gDZk7LXfKYctkQVl'],
]);

/* istanbul ignore next */
export const auth0ClientFactory = (windowObject: Window, appConfig: AppConfig) => {
  let organizationId: string | undefined;
  const enableOrganizationFeature = appConfig.auth0.useOrganizations ?? false;

  if (enableOrganizationFeature) {
    const organizationSubdomain = windowObject.location.host.split('.')[0];
    organizationId = tenantMap.get(organizationSubdomain);
    console.info('running multi organization feature', organizationSubdomain, organizationId);
  }

  const clientOptions: Auth0ClientOptions = {
    domain: appConfig.auth0.domain,
    clientId: appConfig.auth0.clientID,
    useRefreshTokens: true, // as recommended by Andreas Grimm
    cacheLocation: 'localstorage', // as recommended by Andreas Grimm
    useRefreshTokensFallback: true,
    authorizationParams: {
      audience: appConfig.auth0.audience,
      scope: appConfig.auth0.scope,
      ...(appConfig.auth0.apiConnection ? { connection: appConfig.auth0.apiConnection } : {}),
      ...(appConfig.auth0.organization ? { organization: appConfig.auth0.organization } : {}),
      ...(enableOrganizationFeature != null ? { organization: organizationId } : {}),
      redirect_uri: `${windowObject.location.origin}`,
    },
  };

  let authClient: Auth0Client;

  return from(
    (async () => {
      // this is the same as the code from createAuth0Client but allows us to handle any errors thrown up by
      // checkSession (happens when the refresh_token has expired
      authClient = new Auth0Client(clientOptions);
      await authClient.checkSession();
      return authClient;
    })(),
  ).pipe(
    catchError((err) => {
      console.error(err);
      // there is currently a limitation in Auth0 client - if an expired refresh token is store in local storage it errors and doesn't
      // cleanly clear the local storage so we have to do it manually. see https://github.com/auth0/auth0-spa-js/issues/449
      authClient.logout({
        onRedirect: async (_url) => {},
      });

      return of(authClient);
    }),
    // Every sub receives the same shared value
    intShareReplay(1),
  );
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtDecode = jwtDecode;
  readonly INTRANET_ROLES = 'https://intranet.akelius.com/claims/roles';
  readonly TENANTS = 'https://akelius.com/claims/tenant-names';
  readonly DEPARTMENT = 'https://akelius.com/department';
  readonly STORAGE_CALLBACK_ITEM = 'storageAuthCallbackItem';

  constructor(
    private router: Router,
    @Inject(AuthClientInjectable) private auth0Client$: Observable<Auth0Client>,
    @Inject(WINDOW_OBJECT) private _window: Window,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();

    this.metadataForAnalytics$.subscribe({
      next: (metadata: { department: string; country: string }) => {
        GoogleAnalyticsHelpers.setDataLayerUser(metadata);
      },
      error: (error) => console.error('Error retrieving user data for analytics:', error),
    });
  }

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    filter((isAuthenticated) => isAuthenticated !== null),
    tap({
      next: (res) => (this.loggedIn = res),
    }),
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );

  metadataForAnalytics$: Observable<{ department: string; country: string }> = this.isAuthenticated$.pipe(
    filter((authenticated) => authenticated),
    switchMap(() => this.getIdToken$()),
    filter((token) => !!token),
    map((idTokenPayload) => {
      return {
        department: idTokenPayload[this.DEPARTMENT],
        country: idTokenPayload?.address?.country,
      };
    }),
  );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<User | null>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean | null = null;
  loggingIn = false;

  getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      catchError((err) => {
        console.error('got an error on access token retrieval, logging in...', err);
        this._triggerRelogin();
        return of(null);
      }),
      filterNull,
    );
  }

  getIdToken$(): Observable<AkeliusIDToken> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims())),
      map((idToken: IdToken | undefined) => idToken as unknown as AkeliusIDToken),
      catchError((err) => {
        console.error('got an error on id token retrieval, logging in...', err);
        this._triggerRelogin();
        return of(null);
      }),
      filterNullish,
    );
  }

  private _triggerRelogin(redirectPath?: string) {
    // this login process can cause e2e tests to break so we check whether this is running under cypress and
    // avoid re-logging in
    // @ts-ignore
    if (!(<object>this._window)['Cypress']) {
      this.logout(); // if we don't do this it tries to use the old refresh_token after login
      this.login(redirectPath || this.router.routerState.snapshot.url);
    }
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$() {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser()) as Observable<Auth0User>),
      filter((user: Auth0User) => !!user),
      tap({
        next: (user: Auth0User) => {
          const userData = { ...user, id: null };
          const userIdParts = user['https://akelius.com/claims/userid']?.split('|');
          if (userIdParts?.length) {
            userData.id = userIdParts[userIdParts.length - 1];
          }
          this.userProfileSubject$.next(userData as unknown as User);
        },
      }),
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    if (!this.loggingIn) {
      this.loggingIn = true;

      // A desired redirect path can be passed to login method
      // (e.g., from a route guard)
      // Ensure Auth0 client instance exists
      this.auth0Client$.subscribe({
        next: (client: Auth0Client) => {
          // Call method to log in
          client
            .loginWithRedirect({
              authorizationParams: {
                // can get an invalid state error if the redirect URI is cached
                redirect_uri: `${this._window.location.origin}/login?cache=${Date.now()}`,
              },
              appState: { target: redirectPath },
            })
            .then();
        },
      });
    }
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = this._window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap({
          next: (cbRes) => {
            // Get and set target redirect route from callback results
            targetRoute = cbRes?.appState?.target || '/';
            this.initStorageCallbackItem(targetRoute);
          },
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([this.getUser$(), this.isAuthenticated$]);
        }),
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe({
        next: ([_user, _loggedIn]) => {
          // Redirect to target route after callback processing
          this.router.navigateByUrl(decodeURI(targetRoute)).then((success) => {
            if (success) {
              sessionStorage.removeItem(this.STORAGE_CALLBACK_ITEM);
            }
          });
        },
        error: (_error) => {
          this.handleAuthCallbackError();
        },
      });
    }
  }

  private initStorageCallbackItem(targetRoute: string) {
    const storageCallbackItem = sessionStorage.getItem(this.STORAGE_CALLBACK_ITEM);
    if (storageCallbackItem) {
      // delete possibly added item for other route from past
      const callbackItem = JSON.parse(storageCallbackItem);
      if (callbackItem.targetRoute !== targetRoute) {
        sessionStorage.removeItem(this.STORAGE_CALLBACK_ITEM);
        this.createStorageCallbackItem(targetRoute);
      }
    } else {
      this.createStorageCallbackItem(targetRoute);
    }
  }

  private createStorageCallbackItem(targetRoute: string) {
    // add new item to storage
    sessionStorage.setItem(
      this.STORAGE_CALLBACK_ITEM,
      JSON.stringify({
        targetRoute,
        attempts: 0,
      }),
    );
  }

  private handleAuthCallbackError() {
    const callbackItem = JSON.parse(sessionStorage.getItem(this.STORAGE_CALLBACK_ITEM) as string);
    if (callbackItem?.attempts < 3) {
      callbackItem.attempts += 1;
      sessionStorage.setItem(this.STORAGE_CALLBACK_ITEM, JSON.stringify(callbackItem));
      this._triggerRelogin(callbackItem.targetRoute);
    } else {
      // something really is going wrong here.
      // clear session storage from callbackItem
      sessionStorage.removeItem(this.STORAGE_CALLBACK_ITEM);
      // ... and send user back to login
      this.logout();
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe({
      next: (client: Auth0Client) => {
        // Call method to log out
        client.logout({
          clientId: this.appConfig.auth0.clientID,
          logoutParams: {
            returnTo: `${this._window.location.origin}`,
            federated: true,
          },
        });
      },
    });
  }

  hasRole(role: string | string[]): Observable<boolean> {
    const roles = Array.isArray(role) ? role : [role];

    return this.getTokenSilently$().pipe(
      map((token) => this.jwtDecode<{ [key: string]: string[] }>(token)),
      map((accessTokenPayload) => {
        return accessTokenPayload[this.INTRANET_ROLES]
          ? !!accessTokenPayload[this.INTRANET_ROLES].filter((r: string) => roles.includes(r)).length
          : false;
      }),
    );
  }

  getTenants$(): Observable<string[]> {
    return this.getTokenSilently$().pipe(
      map((token: string) => this.jwtDecode<{ [key: string]: string[] }>(token)),
      map((accessTokenPayload: { [key: string]: string[] }) => {
        return accessTokenPayload[this.TENANTS] ?? [];
      }),
    );
  }

  hasCountry(country: string | string[]): Observable<boolean> {
    const countries = Array.isArray(country) ? country : [country];

    return this.getIdToken$().pipe(
      map((token) => (token?.address?.country ? countries.includes(token.address.country) : false)),
    );
  }
}
