import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum FileType {
  video = 'video',
  audio = 'audio',
  image = 'image',
  document = 'document',
}

export const mimeTypeMapping: { [key: string]: string } = {
  [FileType.audio]: 'audio',
  [FileType.image]: 'image',
  [FileType.video]: 'video',
};

const MB_10_IN_BYTES = 10485760;

interface Preview {
  type: 'icon' | 'picture';
  payload: string;
  file: File;
}

export type state = 'neutral' | 'success' | 'error' | 'deleted';

@Component({
  selector: 'app-upload-file-preview',
  templateUrl: './upload-file-preview.component.html',
  styleUrls: ['./upload-file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilePreviewComponent implements OnInit, OnChanges {
  private readonly previewMimeTypes = ['image/gif', 'image/jpeg', 'image/png'];
  private readonly defaultIcon = 'ak-document';
  private readonly statusIcons: { [key in state]: string | null } = {
    success: 'ak-success',
    error: 'ak-error',
    deleted: 'ak-delete',
    neutral: null,
  };

  @Input() file: File;
  @Input() progress: number;
  @Input() state: state;
  @Input() fileTooLarge = false;

  previewSubject = new BehaviorSubject<Preview | null>(null);

  constructor() {}

  ngOnInit(): void {
    this._processFile(this.file);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._processFile(this.file);
  }

  private _processFile(file: File) {
    const fileMimeType = file ? file.type : null;

    if (fileMimeType && this.previewMimeTypes.includes(fileMimeType) && file.size < MB_10_IN_BYTES) {
      this._getFileData(file).subscribe({
        next: (filePreviewUrl: string) => {
          this.previewSubject.next({
            type: 'picture',
            payload: filePreviewUrl,
            file,
          });
        },
      });
    } else {
      this.previewSubject.next({
        type: 'icon',
        payload: this._getFileTypeIcon(file),
        file,
      });
    }
  }

  private _getFileTypeIcon(file: File) {
    const fileMimeGeneralType = file ? file.type.split('/')[0] : null;
    return fileMimeGeneralType && mimeTypeMapping[fileMimeGeneralType]
      ? `ak-${mimeTypeMapping[fileMimeGeneralType]}`
      : this.defaultIcon;
  }

  private _getFileData(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable<string>((observer) => {
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
    });
  }

  humanFileSize(size: number): string {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const number = size / Math.pow(1024, i);
    return number.toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  getStatusIcon(status: state) {
    return this.statusIcons[status];
  }
}
