import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionBarConfig } from '../action-bar/action-bar.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NotificationType } from '@akelius/angular-ui-kit';

export enum NotificationMessageType {
  warning = 'warning',
  success = 'success',
  error = 'error',
  info = 'info',
  neutral = 'neutral',
}

export interface NotificationConfig {
  type: NotificationMessageType;
  message: string;
  buttons: ActionBarConfig<boolean>;
  hideClose?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent<T> implements OnInit {
  /*
    For now NotificationMessageType is not used here. But lets keep the functionality for maybe upcoming
    changes in design for Popups.
   */
  statusTypeMapping: { [key in NotificationMessageType]?: NotificationType } = {
    [NotificationMessageType.error]: 'error',
    [NotificationMessageType.info]: 'info',
    [NotificationMessageType.success]: 'success',
    [NotificationMessageType.warning]: 'warning',
  };

  types = NotificationMessageType;

  constructor() {}

  @Input() config: NotificationConfig;
  @Input() title: string;

  @Output() buttonClicked = new EventEmitter<T>();

  ngOnInit(): void {}
}
