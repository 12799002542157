import { Pipe, PipeTransform } from '@angular/core';
import { FileTypeIconConfig } from './models/file-type-icon.interface';
import { FILE_TYPE_ICON_LIST } from './file-type-icon.config';

@Pipe({
  name: 'fileTypeIcon',
})
export class FileTypeIconPipe implements PipeTransform {
  transform(fileType: string): string {
    if (fileType.includes('video')) {
      return 'ak-video';
    }
    const icon: FileTypeIconConfig | undefined = FILE_TYPE_ICON_LIST.find((iconConfig: FileTypeIconConfig) =>
      iconConfig.types.find((type: string) => fileType.indexOf(type) >= 0),
    );

    return icon ? icon.icon : 'ak-document';
  }
}
