import { CustomRoutes } from './app-routing.module';
import { PROPERTY_CATALOG_PATH } from './features/property-catalog/data-config/property-api-config';

export const legacyPropertyRoutes: CustomRoutes = [
  {
    path: ':country/:orgaOrGeo/:assetId/:name',
    redirectTo: '/' + PROPERTY_CATALOG_PATH + '/geography_filters/:assetId/:name',
  },
  {
    path: ':country/geography',
    redirectTo: '/' + PROPERTY_CATALOG_PATH + '/geography_filters',
  },
  {
    path: ':country/organization',
    redirectTo: '/' + PROPERTY_CATALOG_PATH + '/organization_filters',
  },
  {
    path: '**',
    redirectTo: '/' + PROPERTY_CATALOG_PATH,
  },
];
