import { HttpRequest } from '@angular/common/http';
import { AppConfig } from '../../../app.config';

export class RequestHelpers {
  static isIntranetRequest(req: HttpRequest<any>, appConfig: AppConfig): boolean {
    return req.url.indexOf(appConfig.backend) >= 0 || req.url.indexOf(appConfig.directUpload) >= 0;
  }

  static isStaffRequest(req: HttpRequest<any>, appConfig: AppConfig): boolean {
    const backendConfig = appConfig.staffApp.backend;

    return req.url.indexOf(backendConfig.gql) !== -1 || req.url.indexOf(backendConfig.rest) !== -1;
  }
}
