import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormlyFieldType } from '../models';
import { FieldSuffixType } from '../models/field-suffix-type.enum';

@Component({
  selector: 'formly-wrapper-addons',
  styles: [
    ':host { display: flex; align-items: flex-start; width: 100%; justify-content: stretch }',
    '.field { flex: 1; }',
    '.field { flex: 1; }',
  ],
  template: `
    <div class="field">
      <ng-container #fieldComponent></ng-container>
    </div>
    <app-help-text-icon
      style="margin: 4px"
      *ngIf="props.addonRight?.type === fieldSuffixType.helpIcon"
      [declaredValue]="props.addonRight.title"
      [helpTextKey]="props.addonRight.text"
      class="help-icon"
    >
    </app-help-text-icon>
  `,
})
export class FieldSuffixComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent!: ViewContainerRef;

  fieldSuffixType = FieldSuffixType;
}
