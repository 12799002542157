import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { TranslationService } from '../services/translation.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(private translationService: TranslationService, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.translationService.currentLang$.pipe(
      take(1),
      switchMap((language: string | null) => {
        const langReq =
          req.url.indexOf(this.appConfig.backend) >= 0
            ? req.clone({
                setHeaders: {
                  'x-akelius-language': language || '',
                },
              })
            : req;

        return next.handle(langReq);
      }),
      catchError((err) => throwError(err))
    );
  }
}

export const LangInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LangInterceptor,
  multi: true,
};
