import { Component, Input, Output, EventEmitter, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PropertyResourceListEntity } from '../../models/property-asset-search.interafces';
import { PropertiesSelection } from '../../../../../features/property-catalog/models/interfaces';

import { filter, debounceTime } from 'rxjs/operators';

const PROPERTY_ASSET_SEARCH = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PropertySearchPickerComponent),
  multi: true,
};

@Component({
  selector: 'app-property-search-picker',
  templateUrl: './property-search-picker.component.html',
  styleUrls: ['./property-search-picker.component.scss'],
  providers: [PROPERTY_ASSET_SEARCH],
})
export class PropertySearchPickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() pending = false;
  @Input() readOnly = false;
  @Input() required = false;

  @Input() properties: PropertyResourceListEntity[] = [];
  @Output() searchTextChange = new EventEmitter<string>();

  propertySearch = new UntypedFormControl({ value: '', disabled: false });

  disabled = false;
  selectedProperties: PropertiesSelection[] = [];
  onTouch: Function = () => {};
  onModelChange: Function = () => {};

  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.propertySearchDisabledState(isDisabled);
  }

  propertySearchDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.propertySearch.disable();
    } else {
      this.propertySearch.enable();
      setTimeout(() => {
        const inputRef = document.getElementById('searchProperties');
        if (inputRef) {
          inputRef.focus();
        } else {
          console.error(' Cannot set focus() on a text field');
        }
      });
    }
    return isDisabled;
  }

  writeValue(value: PropertiesSelection[]) {
    this.selectedProperties = value;
  }

  updateModel(value: PropertiesSelection[]) {
    this.writeValue(value);
    this.onTouch();
    this.onModelChange(this.selectedProperties);
  }

  ngOnInit() {
    this.propertySearch.valueChanges
      .pipe(
        filter((searchTerm) => !!(searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 2)),
        debounceTime(400)
      )
      .subscribe({
        next: (searchTerm) => {
          this.searchTextChange.emit(searchTerm);
        },
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.properties && changes['properties']) {
      // already selected properties should not be shown in selectable list
      this.properties = this.properties.filter(
        (property) => !this.selectedProperties.some((selectedProperty) => selectedProperty.id === property.objectId)
      );
    }

    // disable propertySearch input
    if (changes['readOnly']) {
      this.propertySearchDisabledState(this.readOnly);
    }
  }

  onSelect(property: PropertyResourceListEntity) {
    if (PropertySearchPickerComponent.isPropertyResource(property)) {
      const selectedProperty = { id: property.objectId, name: property.name };
      const selectedProperties = [...this.selectedProperties, selectedProperty];
      this.properties = [];
      this.updateModel(selectedProperties);
    }

    this.clearPropertySearch();
  }

  onDeselect(property: PropertiesSelection) {
    const selectedProperties = this.selectedProperties.filter((listItem) => listItem.id !== property.id);

    this.properties = [];
    this.updateModel(selectedProperties);
    this.clearPropertySearch();
  }

  getCustomPropertyName(property: PropertyResourceListEntity) {
    return `${property.name}, ${property.costCenterNumber}`;
  }

  clearPropertySearch() {
    this.propertySearch.setValue(null, { emitEvent: false });
  }

  private static isPropertyResource(event: PropertyResourceListEntity): event is PropertyResourceListEntity {
    return (event as PropertyResourceListEntity).objectId !== undefined;
  }
}
