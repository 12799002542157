import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Version } from '../../../version/version.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SentryService } from './sentry.service';

@Injectable({
  providedIn: 'root',
})
export class CheckVersionService {
  private initialLoadedBuild: string;

  readonly FIVE_MINUTES = 1000 * 60 * 5;
  updateAvailable$: Observable<boolean>;

  constructor(private httpClient: HttpClient, private sentry: SentryService) {
    this.updateAvailable$ = timer(0, this.FIVE_MINUTES).pipe(
      switchMap((_) => this.getBuildVersion()),
      tap({
        next: (buildTime) => {
          if (!this.initialLoadedBuild) {
            this.initialLoadedBuild = buildTime;
          }
        },
      }),
      map((buildTime) => this.initialLoadedBuild !== buildTime)
    );
  }

  getBuildVersion(): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    return this.httpClient.get<Version>('assets/version/version.json', httpOptions).pipe(
      map((buildData) => buildData.build.time),
      catchError((err) => {
        this.sentry.logError(err);
        return of(this.initialLoadedBuild);
      })
    );
  }
}
