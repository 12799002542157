<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="dots-btn"
  [matTooltip]="'core.button.tooltip.moreActions' | translation"
  [attr.data-test-menu-btn]="dataTestingId"
>
  <mat-icon svgIcon="ak-three-dots-horizontal"></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="hasDebuggerRole" (click)="debugClicked($event)" data-test-id="debug">
    <mat-icon svgIcon="ak-info"></mat-icon>
    <span>{{ 'core.button.info' | translation }}</span>
  </button>
  <button mat-menu-item *ngIf="shareable" (click)="shareClicked($event)" data-test-id="share">
    <mat-icon svgIcon="ak-share"></mat-icon>
    <span>{{ 'core.button.share' | translation }}</span>
  </button>
  <button mat-menu-item *ngIf="downloadable" (click)="downloadClicked($event)" data-test-id="download">
    <mat-icon svgIcon="ak-document"></mat-icon>
    <span>{{ 'core.button.download' | translation }}</span>
  </button>
  <button mat-menu-item *ngIf="notifiable" (click)="notifyClicked($event)" data-test-id="notify">
    <mat-icon svgIcon="ak-mail"></mat-icon>
    <span>{{ 'core.button.notify' | translation }}</span>
  </button>
  <button mat-menu-item [disabled]="!editable" (click)="editClicked($event)" data-test-id="edit">
    <mat-icon svgIcon="ak-edit"></mat-icon>
    <span>{{ 'core.button.edit' | translation }}</span>
  </button>
  <button mat-menu-item [disabled]="!deleteable" (click)="deleteClicked($event)" data-test-id="delete">
    <mat-icon svgIcon="ak-delete"></mat-icon>
    <span>{{ 'core.button.delete' | translation }}</span>
  </button>
  <ng-content></ng-content>
</mat-menu>
