import { TenantState } from './tenant.state';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '../store.service';

export const TenantInitialState: TenantState = {
  tenantConfig: null,
};

@Injectable({
  providedIn: 'root',
})
export class TenantStoreService extends Store<TenantState> implements OnDestroy {
  constructor() {
    super(TenantInitialState);
  }
}
