import { Pipe, PipeTransform } from '@angular/core';
import { DataHelpers } from '../../../../core/helpers/data.helpers';

export enum FormatDateType {
  Date = 'date',
  DateTime = 'dateTime',
}

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, format: FormatDateType = FormatDateType.Date): string {
    if (!value) {
      return '';
    }

    switch (format) {
      case FormatDateType.Date:
        return DataHelpers.formatDate(value);
      case FormatDateType.DateTime:
        return DataHelpers.formatDateWithTime(value);
      default:
        console.error('format type is unknown', format);
        return value;
    }
  }
}
