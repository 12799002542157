import {WrapperOption} from '@ngx-formly/core/lib/models';

import { FieldSuffixComponent, FileSpecificPanelComponent, MoveUnknownTypeWrapperComponent } from '../wrapper';
import { FormlyWrapperType } from '../models';

export const wrappers: WrapperOption[] = [
  {
    name: FormlyWrapperType.fileSpecificPanel,
    component: FileSpecificPanelComponent,
  },
  {
    name: FormlyWrapperType.addons,
    component: FieldSuffixComponent,
  },
  {
    name: FormlyWrapperType.moveUnknownAsset,
    component: MoveUnknownTypeWrapperComponent,
  }
];
