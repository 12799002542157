import { Inject, Injectable } from '@angular/core';
import { DataService } from '../../../core/services/data.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import {
  DISTRICTS_KEY,
  METROPOLITANS_KEY,
  MUNICIPALITIES_KEY,
  NEIGHBORHOODS_KEY,
  PROVINCES_KEY,
} from '../../../shared/domain/property-catalog/models/property.variables';
import { AssetData, AssetDataAjax, AssetSummaryInfo, AssetTreeNode, AssetTreeNodeResponse } from '../models/interfaces';
import { ElementsToMove, MoveAssetsAjaxResponse } from '../models/movable-assets.interfaces';
import { UISchemaConfig } from '../models/ui-schema.interfaces';
import { PropertyUiConfigService } from './property-ui-config.service';
import { KeyFactsAjaxResponse, ResponsiblePersonsEntity } from '../models/asset-overview.interfaces';
import { FormValue, GeoOptions } from '../store/asset-detail/asset-detail-state';
import { paramIncludeInactive } from '../data-config/property-api-config';
import { TranslationNamespace } from '../../../core/models/translation.variables';
import { PropertyListResponse } from '../models/property-list-response.interfaces';
import { Action } from '../models/asset-action.interfaces';
import { Investments } from '../models/asset-investments.interfaces';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class PropertyDataService extends DataService {
  private propertyApiRoot = this.appConfig.propertyCatalogApiRoot;

  constructor(
    private http: HttpClient,
    private _uiDataService: PropertyUiConfigService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    super();
  }

  private static _convertGeoData(data: GeoOptions): GeoOptions {
    return {
      [METROPOLITANS_KEY]: data.metropolitanAreaOptions,
      [MUNICIPALITIES_KEY]: data.municipalityOptions,
      [DISTRICTS_KEY]: data.districtOptions,
      [NEIGHBORHOODS_KEY]: data.neighborhoodOptions,
      [PROVINCES_KEY]: data.provinceOptions,
    };
  }

  getAssetTree(objId: string): Observable<AssetTreeNodeResponse> {
    // get data from API
    return this.http
      .get<AssetTreeNodeResponse>(
        `${this.appConfig.backend}${this.appConfig.propertyCatalogApiRoot}/properties/${objId}/tree?actions=true&uiInfo=true&${paramIncludeInactive}`,
      )
      .pipe(catchError(this.handleError));
  }

  getAssetTreeByObjectId(objId: string): Observable<AssetTreeNode> {
    const params = this.toHttpParams({ includeGeoAddress: false, includeInactive: true, uiInfo: true });

    return this.http
      .get<AssetTreeNode>(`${this.appConfig.backend}${this.appConfig.propertyCatalogApiRoot}/objects/${objId}/tree`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  loadAssetInfoByObjectId(objId: string): Observable<AssetSummaryInfo> {
    const url = `${this.appConfig.backend}propertycatalog/objects/${objId}/info?${paramIncludeInactive}`;

    return this.http.get<AssetSummaryInfo>(`${url}`).pipe(catchError(this.handleError));
  }

  getProperties(params: { [name: string]: string | string[] }) {
    return this.http
      .get<PropertyListResponse>(`${this.appConfig.backend}properties/`, { params: this.toHttpParams(params) })
      .pipe(catchError(this.handleError));
  }

  getPropertyById(id: string): Observable<AssetTreeNode> {
    const params = { includeGeoAddress: false, includeInactive: true };
    const url = `${this.appConfig.backend}${this.appConfig.propertyCatalogApiRoot}/objects/${id}/info?${paramIncludeInactive}`;

    return this.http.get<AssetTreeNode>(url, { params: this.toHttpParams(params) }).pipe(catchError(this.handleError));
  }

  getResponsiblePersonsByPropertyId(propertyId: string): Observable<ResponsiblePersonsEntity[]> {
    return this.http
      .get<
        ResponsiblePersonsEntity[]
      >(`${this.appConfig.backend}api/RES/rest/persons-by-property-id/${propertyId}?includeExternalPersons=true`)
      .pipe(catchError(this.handleError));
  }

  getAssetKeyfacts(data: AssetTreeNode): Observable<{ assetData: AssetData; uiSchema: UISchemaConfig }> {
    return forkJoin([
      this.getAssetData(data._links.self.href),
      this._uiDataService.getUIConfig(data.type).pipe(catchError(this.handleError)),
    ]).pipe(
      map(([assetData, uiSchema]) => {
        const preparedAsset: AssetData = {
          ...assetData,
          _links: data._links,
          _ui: data._ui,
          type: data.type,
          typeKey: uiSchema.type.dictionaryKey,
          assetCategories: uiSchema.type.assetCategories,
          assetCategoryKeys: uiSchema.type.assetCategoryKeys,
          fieldDefinitions: uiSchema.fields.map((field) => ({
            ...field,
            helpTextKey: TranslationNamespace.procaUiConfig + field.helpTextKey,
          })),
          helpTextKey: TranslationNamespace.procaUiConfig + uiSchema.type.helpTextKey,
        };

        return {
          assetData: preparedAsset,
          uiSchema,
        };
      }),
      take(1),
    );
  }

  getMoveOptions(action: Action): Observable<MoveAssetsAjaxResponse> {
    const link = action.href;
    return this.http
      .get<MoveAssetsAjaxResponse>(`${this.appConfig.backend}${this.propertyApiRoot}${link}?${paramIncludeInactive}`)
      .pipe(catchError(this.handleError));
  }

  getPropertyData(propertyId: string): Observable<AssetDataAjax> {
    return this.http
      .get<AssetDataAjax>(
        `${this.appConfig.backend}${this.propertyApiRoot}/properties/${propertyId}?${paramIncludeInactive}`,
      )
      .pipe(catchError(this.handleError));
  }

  getAssetData(link: string): Observable<AssetDataAjax> {
    return this.http
      .get<AssetDataAjax>(`${this.appConfig.backend}${this.propertyApiRoot}${link}?${paramIncludeInactive}`)
      .pipe(catchError(this.handleError));
  }

  executeRemoteAction<T = any>(
    data: { dto: { [key: string]: any }; objectId: string | undefined }[] | null,
    actionData: Action,
  ): Observable<T> {
    return this.http
      .request<T>(
        actionData.verb,
        `${this.appConfig.backend}${this.propertyApiRoot}${actionData.href}?${paramIncludeInactive}`,
        { body: data },
      )
      .pipe(catchError(this.handleError));
  }

  deleteAsset(url: string) {
    return this.http
      .delete(`${this.appConfig.backend}${this.propertyApiRoot}${url}?${paramIncludeInactive}`)
      .pipe(catchError(this.handleError));
  }

  getGeoData(countryId: number, geoValues?: { [paramName: string]: string }): Observable<GeoOptions> {
    // combine the selected country and the selected field parameters to make the final parameter list
    let selectedFilters = {
      countryIsoNumeric: countryId,
    };

    if (geoValues) {
      selectedFilters = {
        ...selectedFilters,
        ...geoValues,
      };
    }

    return this.http
      .post<GeoOptions>(`${this.appConfig.backend}${this.propertyApiRoot}/geo/selectable-options/`, selectedFilters)
      .pipe(map(PropertyDataService._convertGeoData), catchError(this.handleError));
  }

  moveElements(elements: ElementsToMove, moveAction: Action): Observable<string> {
    return this.http
      .put(`${this.appConfig.backend}${this.propertyApiRoot}${moveAction.href}?${paramIncludeInactive}`, elements, {
        responseType: 'text',
      })
      .pipe(catchError(this.handleError));
  }

  moveAssetToTarget(targetId: string, moveAction: Action): Observable<string> {
    return this.http
      .put(`${this.appConfig.backend}${this.propertyApiRoot}${moveAction.href}?${paramIncludeInactive}`, targetId, {
        responseType: 'text',
      })
      .pipe(catchError(this.handleError));
  }

  getReportData(assetId: string, url: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this.appConfig.backend}properties/${assetId}/${url}`, { observe: 'response', responseType: 'blob' })
      .pipe(catchError(this.handleError));
  }

  exportAsset(assetId: string): Observable<any> {
    return this.http
      .post(`${this.appConfig.backend}property-documents/export-to-sharepoint/${assetId}`, null, {})
      .pipe(catchError(this.handleError));
  }

  getUIConfig(type: string): Observable<UISchemaConfig> {
    return this._uiDataService.getUIConfig(type);
  }

  getInvestmentsByAssetId(id: string): Observable<Investments> {
    return this.http
      .get<Investments>(`${this.appConfig.backend}properties/${id}/investments`)
      .pipe(catchError(this.handleError));
  }

  getKeyFactsByAssetId(id: string): Observable<KeyFactsAjaxResponse> {
    return this.http
      .get<KeyFactsAjaxResponse>(`${this.appConfig.backend}properties/${id}/keyfacts`)
      .pipe(catchError(this.handleError));
  }

  getCostCentersByCountry(params = {}) {
    return this.http
      .get(`${this.appConfig.backend}${this.propertyApiRoot}/cost-centers`, { params: this.toHttpParams(params) })
      .pipe(catchError(this.handleError));
  }

  createProperty(payload: FormValue) {
    return this.http
      .post(`${this.appConfig.backend}${this.propertyApiRoot}/properties`, payload)
      .pipe(catchError(this.handleError));
  }

  getTableData(url: string): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.appConfig.backend}${this.propertyApiRoot}${url}`)
      .pipe(catchError(this.handleError));
  }

  deleteTableData(url: string) {
    return this.http
      .delete(`${this.appConfig.backend}${this.propertyApiRoot}${url}`)
      .pipe(catchError(this.handleError));
  }

  sendTableUpdate(data: { [key: string]: any }, actionData: Action) {
    return this.http
      .request<{}>(actionData.verb, `${this.appConfig.backend}${this.propertyApiRoot}${actionData.href}`, {
        body: data,
      })
      .pipe(catchError(this.handleError));
  }

  getAssetTypeOptions(url: string): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.appConfig.backend}${this.propertyApiRoot}${url}`)
      .pipe(catchError(this.handleError));
  }

  sendAssetTypeUpdate(data: string, action: Action): Observable<AssetDataAjax> {
    return this.http
      .request<AssetDataAjax>(action.verb, `${this.appConfig.backend}${this.propertyApiRoot}${action.href}`, {
        body: data,
      })
      .pipe(catchError(this.handleError));
  }

  getAssetDetail(url: string): Observable<any> {
    return this.http.get(`${this.appConfig.backend}${this.propertyApiRoot}${url}`).pipe(catchError(this.handleError));
  }
}
