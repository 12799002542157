// naming of properties
import { ResponsiblePersonsEntity } from '../../../../features/property-catalog/models/asset-overview.interfaces';

export const ANTENNA = 'antenna';
export const APARTMENT = 'apartment';
export const ATTIC = 'attic';
export const BALCONY = 'balcony';
export const BASEMENT = 'basement';
export const BATHROOM = 'bathroom';
export const BICYCLEROOM = 'bicycleroom';
export const BILLBOARD = 'billboard';
export const BOILERROOM = 'boilerroom';
export const BUILDING = 'building';
export const BUILDINGPART = 'buildingpart';
export const CLOSET = 'closet';
export const COMMERCIAL = 'commercial';
export const COMMONAREA = 'commonarea';
export const COMPONENT = 'component';
export const COMPUTERROOM = 'computerroom';
export const COOLINGANDVENTILATION = 'coolingandventilation';
export const CORRIDOR = 'corridor';
export const COSTCENTER = 'costcenter';
export const DOMESTICHOTWATER = 'domestichotwater';
export const DRYINGAREA = 'dryingarea';
export const ENERGYUNIT = 'energyproductionunit';
export const ELEVATOR = 'elevator';
export const ENTRANCE = 'entrance';
export const FORESTAREA = 'forestarea';
export const GAMEROOM = 'gameroom';
export const GARAGE = 'garage';
export const GARBAGEAREA = 'garbagearea';
export const GARDEN = 'garden';
export const GROUNDAREA = 'groundarea';
export const GYM = 'gym';
export const HALLWAY = 'hallway';
export const HEATING = 'heating';
export const HUNTINGAREA = 'huntingarea';
export const KITCHEN = 'kitchen';
export const LAND = 'land';
export const LAUNDRYAREA = 'laundryarea';
export const LOBBY = 'lobby';
export const LOCKERROOM = 'lockerroom';
export const LOGGIA = 'loggia';
export const MAILAREA = 'mailarea';
export const MAINTENANCEROOM = 'maintenanceroom';
export const PARKINGSPACE = 'parkingspace';
export const PRAMROOM = 'pramroom';
export const PROPERTY = 'property';
export const OTHERRENTAL = 'otherrentalobject';
export const RENTABLEBUILDING = 'rentablebuilding';
export const RENTABLESTORAGE = 'rentablestorage';
export const ROOM = 'room';
export const SPACE = 'space';
export const STAIRCASE = 'staircase';
export const STORAGE = 'storage';
export const STORAGEROOM = 'storageroom';
export const STORY = 'story';
export const SYSTEM = 'system';
export const TECHNICALROOM = 'technicalroom';
export const TERRACE = 'terrace';
export const TOILETROOM = 'toiletroom';
export const UTILITY = 'utility';
export const UTILITYROOM = 'utilityroom';
export const VERANDA = 'veranda';
export const VESTIBULE = 'vestibule';

// prefix of actions
export const CREATE = 'create';
export const EDIT = 'edit';
export const DELETE = 'delete';
export const MOVE = 'move';
export const MOVABLE = 'movable';
export const CHANGE = 'change';

export const TO_SELF = 'to_self';
export const TO_OTHER = 'to_other';

export const GET_CHILDREN = 'get-children';
export const GET_CERTIFICATES = 'get-table-certificate';
export const CREATE_CERTIFICATES = 'create-table-certificate';

// naming of geo categories
export const METROPOLITANS_KEY = 'address.metropolitanArea.objectId';
export const MUNICIPALITIES_KEY = 'address.municipality.objectId';
export const DISTRICTS_KEY = 'address.district.objectId';
export const NEIGHBORHOODS_KEY = 'address.neighborhood.objectId';
export const PROVINCES_KEY = 'address.province.objectId';

const DEFAULT_ICON = 'ak-common-area';

export const ICONS_LIST: { [key: string]: string } = {
  [ANTENNA]: 'ak-antenna-sat',
  [APARTMENT]: 'ak-bed',
  [ATTIC]: 'ak-attic',
  [BALCONY]: 'ak-balcony',
  [BASEMENT]: 'ak-basement',
  [BATHROOM]: 'ak-bathtub',
  [BICYCLEROOM]: 'ak-bike',
  [BILLBOARD]: 'ak-billboard',
  [BOILERROOM]: 'ak-boiler-room',
  [BUILDINGPART]: 'ak-building-part',
  [BUILDING]: 'ak-building',
  [CLOSET]: 'ak-closet',
  [COMMERCIAL]: 'ak-commercial',
  [COMMONAREA]: 'ak-common-area',
  [COMPONENT]: DEFAULT_ICON,
  [COMPUTERROOM]: 'ak-computer',
  [COOLINGANDVENTILATION]: 'ak-ventilation',
  [CORRIDOR]: 'ak-hallway',
  [COSTCENTER]: 'ak-cost-center',
  [DOMESTICHOTWATER]: 'ak-water-drop-hot',
  [DRYINGAREA]: 'ak-drying-area',
  [ELEVATOR]: 'ak-elevator',
  [ENERGYUNIT]: 'ak-energy',
  [ENTRANCE]: 'ak-gate',
  [FORESTAREA]: 'ak-forest-area',
  [GAMEROOM]: 'ak-game-controller',
  [GARAGE]: 'ak-car',
  [GARBAGEAREA]: 'ak-trash-can-floor',
  [GARDEN]: 'ak-garden-flower',
  [GROUNDAREA]: 'ak-ground',
  [GYM]: 'ak-dumbbell',
  [HALLWAY]: 'ak-hallway',
  [HEATING]: 'ak-heating',
  [HUNTINGAREA]: 'ak-hunting-area',
  [KITCHEN]: 'ak-stove',
  [LAND]: 'ak-rentable-areas',
  [LAUNDRYAREA]: 'ak-laundry',
  [LOBBY]: 'ak-armchair',
  [LOCKERROOM]: 'ak-hanger',
  [LOGGIA]: 'ak-loggia',
  [MAILAREA]: 'ak-mailbox',
  [MAINTENANCEROOM]: 'ak-maintenance-room',
  [OTHERRENTAL]: 'ak-document',
  [PARKINGSPACE]: 'ak-parking-lot',
  [PRAMROOM]: 'ak-pram',
  [PROPERTY]: 'ak-property',
  [RENTABLEBUILDING]: 'ak-rentable-buildings',
  [RENTABLESTORAGE]: 'ak-rentable-storage',
  [ROOM]: 'ak-room',
  [SPACE]: 'ak-floor-plan',
  [STAIRCASE]: 'ak-staircase',
  [STORAGEROOM]: 'ak-storage-room',
  [STORAGE]: 'ak-storage-room',
  [STORY]: 'ak-storey',
  [SYSTEM]: 'ak-gear',
  [TECHNICALROOM]: 'ak-technical-room',
  [TERRACE]: 'ak-terrace',
  [TOILETROOM]: 'ak-toilet',
  [UTILITY]: 'ak-spanner',
  [UTILITYROOM]: 'ak-spanner',
  [VERANDA]: 'ak-veranda',
  [VESTIBULE]: 'ak-vestibule',

  default: DEFAULT_ICON,
};

export enum SuffixType {
  INTERNET_SPEED = 'megabitspersecond',
  AREA = 'area',
}

// property type
export const FOREST = 'FOREST';
