import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import {FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio-group',
  styleUrls: ['./radio-group.component.scss'],
  template: `
    <mat-radio-group [formControl]="formControl" [formlyAttributes]="field" [attr.data-test-id]="field.key">
      <mat-radio-button
        *ngFor="let option of props.options!"
        [value]="option.value"
        [checked]="option.checked"
        [disabled]="option.disabled"
      >
        <span [ngClass]="{ 'is-inactive': option.inactive }">
          <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
          {{ option.title }}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  `,
})
export class FormlyRadioGroupComponent extends FieldType<FieldTypeConfig> {}
