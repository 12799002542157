import { Inject, Injectable } from '@angular/core';
import { captureMessage, captureException, showReportDialog, addBreadcrumb, setTag, Breadcrumb } from '@sentry/browser';
import { AppError } from '../common';
import * as Sentry from '@sentry/browser';
import { version } from '../../../version/version';
import { APP_CONFIG, AppConfig } from '../../../app.config';

export interface SentryDialogOptions {
  email?: string;
  name?: string;
}

export enum SentryCustomEventType {
  translationMissing = 'missing translation',
}

export interface SentryTags {
  type: string;
  payload: string;
  url: string;

  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {
    Sentry.init({
      dsn: 'https://9464f9fab49a43f6a70c98d781488285@sentry.io/1477315',
      environment: this.appConfig.envName,
      release: 'INT-' + version.git.commitHash,
      // this is a harmless error but there is no way to prevent it so we suppress it fully
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }

  showErrorDialogAndLog(error: Error, options: SentryDialogOptions = {}) {
    if (this.appConfig.useSentry) {
      showReportDialog({
        ...options,
        eventId: this.generateEvent(error),
      });
    } else {
      console.error('suppressed SentryService.showErrorDialogAndLog', error);
    }
  }

  logCustomEvent(message: string, tags?: SentryTags) {
    if (this.appConfig.useSentry) {
      captureMessage(message, { level: 'error', tags });
    } else {
      console.error('suppressed SentryService.logCustomEvent', message, tags);
    }
  }

  logError(error: Error) {
    if (this.appConfig.useSentry) {
      captureException(error);
    } else {
      console.error('suppressed SentryService.logError', error);
    }
  }

  addBreadcrumb(breadcrumb: Breadcrumb) {
    addBreadcrumb(breadcrumb);
  }

  addTag(tagName: string, tagValue: string) {
    if (this.appConfig.useSentry) {
      setTag(tagName, tagValue);
    }
  }
  private generateEvent(error: AppError | Error): string {
    const errorToLog = error instanceof AppError ? error.originalError : error;

    return captureException(errorToLog);
  }
}
