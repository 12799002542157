<div
  data-test-id-file-preview
  class="preview-container"
  [ngClass]="{ success: state === 'success', error: state === 'error' }"
>
  <ng-container *ngIf="previewSubject | async as preview">
    <img class="preview preview-img" *ngIf="preview.type === 'picture'" [src]="preview.payload" alt="preview" />
    <mat-icon class="preview" *ngIf="preview.type === 'icon'" [svgIcon]="preview.file | fileIcon | async"></mat-icon>
  </ng-container>

  <div class="main-container">
    <div class="detail">
      <mat-icon
        class="status-icon"
        *ngIf="state !== 'neutral'"
        [ngClass]="{ success: state === 'success', error: state === 'error', deleted: state === 'deleted' }"
        [svgIcon]="getStatusIcon(state)"
      ></mat-icon>
      <div class="label">
        <div>{{ file?.name }}</div>
        <div [ngClass]="{ size: true, oversize: fileTooLarge }">{{ humanFileSize(file?.size) }}</div>
      </div>

      <div class="control">
        <ng-content></ng-content>
      </div>
    </div>

    <div class="spacer" *ngIf="!progress"></div>
    <app-progress-bar *ngIf="progress" [progress]="progress"></app-progress-bar>
  </div>
</div>
