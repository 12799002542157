import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { TranslationMap } from './translation.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { TranslationNamespace } from '../models/translation.variables';
import { SentryService } from './sentry.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';

export interface TranslationsLoaded {
  fallbackLoaded: boolean;
  translations: TranslationMap;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationLoaderService extends DataService {
  constructor(
    private http: HttpClient,
    private sentry: SentryService,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    super();
  }

  // called by client code to get a combined map of all translations
  loadAllTranslations(languageCode = 'en'): Observable<TranslationsLoaded> {
    return combineLatest([
      this.loadIntranetTranslations(languageCode),
      this.loadProcaTranslations(languageCode),
      this.loadGeoTranslations(),
    ]).pipe(
      map(([intranetTranslations, procaTranslations, geoTranslations]) => {
        return {
          translations: {
            ...intranetTranslations,
            ...this.prefixProcaTranslations(procaTranslations ?? {}),
            ...geoTranslations,
          },
          fallbackLoaded: !(intranetTranslations && procaTranslations && geoTranslations),
        };
      }),
      catchError((_error) => {
        return this.loadFallbackTranslations();
      }),
    );
  }

  // get intranet dictionary service translations
  private loadIntranetTranslations(languageCode: string): Observable<TranslationMap | null> {
    return this.loadDictionaryTranslations('INT', languageCode);
  }

  // get proca dictionary service translations
  private loadProcaTranslations(languageCode: string) {
    return this.loadDictionaryTranslations('PRC', languageCode);
  }

  private loadDictionaryTranslations(appId: string, languageCode: string) {
    return this.http
      .get<TranslationMap>(
        `${this.config.backend}dictionary/${appId}/env/${this.config.dictionaryServiceEnv}/translations/ln/${languageCode}`,
      )
      .pipe(
        retry(1),
        catchError((err) => {
          this.sentry.logError(err);
          return of(null);
        }),
      );
  }

  private loadGeoTranslations(): Observable<TranslationMap> {
    // Sad story: because the geo translations are not stable, they are generated by the BE and so we still have
    // to retrieve them separately. When the backend have time, they can build them with the new key format (dynamic.node...etc)
    // so that we don't have to. But for now, there are hacks 😢

    return this.http.get<TranslationMap>(`${this.config.backend}translations/en`).pipe(
      map((translations) => {
        return Object.keys(translations).reduce((acc: { [key: string]: string }, key) => {
          acc[`dynamic.node.filter.tag.${key}`] = translations[key];
          return acc;
        }, {});
      }),
      retry(1),
    );
  }

  private loadFallbackTranslations(): Observable<TranslationsLoaded> {
    return this.http.get<TranslationMap>(`assets/translations/fallback-translations_en.json`).pipe(
      retry(1),
      map((translations) => ({ translations, fallbackLoaded: true })),
    );
  }

  private prefixProcaTranslations(translationMap: TranslationMap): TranslationMap {
    return Object.entries(translationMap).reduce(
      (acc: TranslationMap, [key, value]: [string, string]) => ({
        ...acc,
        [`${TranslationNamespace.procaUiConfig}${key}`]: value,
      }),
      {},
    );
  }
}
