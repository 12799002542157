import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filterNullish } from '../../../../support/rxjs-operators';

@UntilDestroy()
@Directive({
  selector: '[access-control]',
})
export class AccessControlDirective {
  private requiredRoles = new BehaviorSubject<string | string[] | null | undefined>(null);

  @Input('roles')
  set disableByRoles(role: string | string[] | undefined) {
    this.requiredRoles.next(role);
  }

  // this replaces the normal [disabled] input
  // otherwise the binding interferes with the updating of the attribute
  @Input('accessControlDisabled') disabled: boolean;

  constructor(
    private el: ElementRef,
    private authService: AuthService,
  ) {
    this.requiredRoles
      .pipe(
        filterNullish,
        switchMap((role) => this.authService.hasRole(role)),
        untilDestroyed(this),
      )
      .subscribe({
        next: (hasRole) => {
          // if the user doesn't have at least one of the roles then we disable the button,
          // if they do, then we maintain the existing disabled state of the element
          this.el.nativeElement.disabled = hasRole ? this.disabled : true;
        },
      });
  }
}
