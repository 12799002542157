import moment, { Moment } from 'moment';
import { MediaItem } from '../../shared/domain/pictures/models/picture.interface';
import { get } from 'lodash-es';

export class DataHelpers {
  static getValueByString(object: { [key: string]: any }, string: string): any {
    return get(object, string);
  }

  // Dates without times are handled in UTC to prevent problems in other timezones
  static formatDate(inputDate: string | Date | Moment): string {
    const formatString = 'YYYY-MM-DD';

    try {
      if (moment.isMoment(inputDate)) {
        return moment.utc(inputDate).format(formatString);
      } else {
        // moment gives a deprecation warning if the date is an invalid string instead of throwing an error
        // using js Date first so that we can check if it's valid
        const dateObject = inputDate instanceof Date ? inputDate : new Date(DataHelpers.normalizeDateString(inputDate));
        return dateObject.toString() === 'Invalid Date' ? '' : moment.utc(dateObject).format(formatString);
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  // Datetimes are handled in the timezone of the user
  static formatDateWithTime(inputDate: string | Date | Moment): string {
    const formatString = 'YYYY-MM-DD HH:mm';

    try {
      if (moment.isMoment(inputDate)) {
        return inputDate.format(formatString);
      } else {
        // moment gives a deprecation warning if the date is an invalid string instead of throwing an error
        // using js Date first so that we can check if it's valid
        const dateObject = inputDate instanceof Date ? inputDate : new Date(DataHelpers.normalizeDateString(inputDate));
        return dateObject.toString() === 'Invalid Date' ? '' : moment(dateObject).format(formatString); // NOT UTC
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  // we get back the dates in extended timezone format e.g. 2015-02-16T12:44:42+0000, Safari and IE can't handle this format and need a :
  // inside the timezone definition (2015-02-16T12:44:42+00:00). Add it in so that it doesn't break....
  static normalizeDateString(date: string): string {
    return date.replace(/([+-]\d\d)(\d\d)$/, '$1:$2');
  }

  static filterQueriesToProperties(picture: MediaItem) {
    const filterCategories: { [key: string]: string[] } = {};

    picture.filterQueries.forEach((filterGroup) => {
      const [category, filter] = filterGroup.split('=');
      filterCategories[category] ? filterCategories[category].push(filter) : (filterCategories[category] = [filter]);
    });

    Object.keys(filterCategories).forEach((category) => {
      if (!picture[category as keyof MediaItem] && (!picture.meta || !picture.meta[category])) {
        picture.meta[category] = filterCategories[category];
      }
    });

    return picture;
  }

  static sortAlphabetically(a: string, b: string): -1 | 1 | 0 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
}
