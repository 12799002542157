import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../../../core/services/translation.service';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(private _translationService: TranslationService) {}

  transform(value: string, args?: any): string {
    if (args) {
      return this._translationService.getTranslationByIdAndParams(value, args);
    } else {
      return this._translationService.getTranslationById(value);
    }
  }
}
