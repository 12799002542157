import { Pipe, PipeTransform } from '@angular/core';
import { DataHelpers } from '../../../../core/helpers/data.helpers';

@Pipe({
  name: 'pluck',
})
export class PluckPipe implements PipeTransform {
  transform(object: {}, key: string): unknown {
    return DataHelpers.getValueByString(object, key);
  }
}
