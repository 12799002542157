import {ExtensionOption} from '@ngx-formly/core/lib/models';
import { addonsExtension } from '../extensions/addons';
import { dataE2EHooks } from '../extensions/data-e2e-hooks.extension';

export const extensions: ExtensionOption[] = [
  {
    name: 'addons',
    extension: {
      onPopulate: addonsExtension,
    },
  },
  {
    name: 'data-e2e',
    extension: {
      prePopulate: dataE2EHooks,
    },
  },
];
