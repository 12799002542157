import { Component } from '@angular/core';
import {FieldTypeConfig} from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'formly-field-textarea',
  template: `
    <textarea
      matInput
      [attr.data-test-id]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [rows]="props.rows"
    ></textarea>
  `,
})
export class FormlyFieldTextAreaComponent extends FieldType<FieldTypeConfig> {}
