import { Injectable } from '@angular/core';
import { NodeService } from '../services/node.service';
import { ContextInitialState, ContextStoreService, PreFillValues } from '../stores/context/context-store.service';
import { ContextState } from '../stores/context/context.state';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { LeafNode } from '../models/node-data.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ContextFacadeService {
  constructor(
    private store: ContextStoreService,
    private nodeService: NodeService,
  ) {}

  set context(context: LeafNode | null) {
    this.store.set<LeafNode | null>('context', context);
  }

  private set loading(loading: boolean) {
    this.store.set<boolean>('loading', loading);
  }

  private set loaded(loaded: boolean) {
    this.store.set<boolean>('loaded', loaded);
  }

  get context$(): Observable<LeafNode> {
    return this.store.select<LeafNode>('context');
  }

  get breadcrumbPath$(): Observable<string> {
    return this.store.select<string>('breadcrumbPath');
  }

  get loading$(): Observable<boolean> {
    return this.store.select<boolean>('loading');
  }

  set selectedEntity(selectedEntity: any) {
    this.store.set<any>('selectedEntity', selectedEntity);
  }

  get selectedEntity$() {
    return this.store.select<any>('selectedEntity');
  }

  set preFillValues(preFillValues: PreFillValues) {
    this.store.set<PreFillValues>('preFillValues', preFillValues);
  }

  get preFillValues$() {
    return this.store.select<PreFillValues>('preFillValues');
  }

  setSelectedEntity<T>(entity: T) {
    this.selectedEntity = entity;
  }

  resetSelectedEntity(): void {
    this.selectedEntity = null;
  }

  resetContext(): void {
    this.context = null;
  }

  setPreFillValue<G>(name: keyof PreFillValues, state: G): void {
    this.preFillValues = {
      ...this.store.value.preFillValues,
      [name]: state,
    } as unknown as PreFillValues;
  }

  get permissions$(): Observable<{ [key: string]: string[] } | null> {
    return this.context$.pipe(
      filter((context) => !!context),
      map((context: LeafNode) => context.permission ?? null),
    );
  }

  private static buildNodePathWithoutQueryParams(nodePath: string, childName: string): string {
    const nodePathWithoutQueryParams =
      nodePath.indexOf('?') < 0 ? nodePath : nodePath.substring(0, nodePath.indexOf('?'));
    return nodePathWithoutQueryParams + '/' + childName;
  }

  private generateContextPath(context: LeafNode, nodePath: string) {
    return {
      ...context,
      children: context?.children?.map((child) => ({
        ...child,
        path: ContextFacadeService.buildNodePathWithoutQueryParams(nodePath, child.name),
      })),
    };
  }

  // if "isRouteNode" is true the context will appear in breadcrumb
  loadNodes(nodePath: string, isRouteNode: boolean): Observable<LeafNode> {
    this.loading = true;
    this.loaded = false;
    return this.nodeService.getNodesData(nodePath).pipe(
      withLatestFrom(this.breadcrumbPath$),
      map(([context, breadcrumbPath]) => {
        const newContext = context.children ? this.generateContextPath(context, nodePath) : context;

        // if the new node is a routeNode, then we update the breadcrumbPath
        const contextState: ContextState = {
          ...ContextInitialState,
          context: { ...newContext },
          breadcrumbPath: isRouteNode && newContext.path ? newContext.path : breadcrumbPath,
          loaded: true,
        };

        return { contextState, context };
      }),
      tap(({ contextState }) => {
        this.store.patch(contextState);
      }),
      map(({ context }) => context),
    );
  }

  loadLeafNodeById(nodeId: string): Observable<LeafNode> {
    this.loading = true;
    this.loaded = false;
    return this.nodeService.getLeafNodeById(nodeId).pipe(
      tap({
        next: (context) => {
          this.store.patch({
            ...ContextInitialState,
            context: { ...context },
            loaded: true,
          });
        },
      }),
    );
  }

  resetStore() {
    this.store.reset();
  }
}
