import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoEntity } from '../../../../features/videos/models';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss',
})
export class VideoPlayerComponent {
  @Input() videoData: VideoEntity;
  @Input() controls = true;

  @Output() videoLoaded = new EventEmitter();
  @Output() videoLoadFailed = new EventEmitter();

  videoIsLoaded() {
    this.videoLoaded.emit();
  }

  videoLoadHasFailed() {
    this.videoLoadFailed.emit();
  }
}
