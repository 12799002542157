export class NotAuthenticatedError extends Error {
  constructor(public originalError?: any) {
    super(originalError.message);
    this.name = NotAuthenticatedError.name;
    Object.setPrototypeOf(this, NotAuthenticatedError.prototype);
  }

  toString() {
    return this.originalError.message;
  }
}
