import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FieldTypeConfig } from '@ngx-formly/core';

import { AutoCompleteStore, initialState } from './auto-compete-store.service';
import { AutocompleteItem } from '../../components';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-field-auto-complete',
  template: `
    <app-auto-complete
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.data-test-id]="field.key"
      [loading]="loading$ | async"
      [items]="items$ | async"
      [selectedItem$]="selectedItem$"
      [placeholder]="props.label"
      (searchTextChanged)="onSearchTextChange($event)"
    ></app-auto-complete>
  `,
  providers: [AutoCompleteStore],
})
export class FormlyAutoCompleteComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  loading$: Observable<boolean>;
  items$: Observable<AutocompleteItem[]>;
  selectedItem$: Observable<AutocompleteItem>;

  searchSubject$ = new Subject<string>();

  constructor(private store: AutoCompleteStore) {
    super();
    this.searchSubject$
      .pipe(
        tap({ next: (searchText: string) => this.store.patch({ ...initialState, searchText, loading: true }) }),
        switchMap((text: string) => this.props.onSearchTextChanged(text) as Observable<AutocompleteItem[]>),
        tap({ next: () => this.store.patch({ loading: false }) })
      )
      .subscribe({
        next: (items) => {
          this.store.patch({ items, loaded: true });
        },
      });
  }

  ngOnInit() {
    this.items$ = this.store.items$;
    this.loading$ = this.store.loading$;
    this.selectedItem$ = this.getSelectedItem();
  }

  onSearchTextChange(text: string) {
    this.searchSubject$.next(text);
  }

  getSelectedItem(): Observable<AutocompleteItem> {
    return this.value && this.props.getSelectedItem
      ? this.props.getSelectedItem(this.value).pipe(
          catchError((_) => {
            this.value = '';
            return EMPTY;
          })
        )
      : EMPTY;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.searchSubject$.complete();
  }
}
