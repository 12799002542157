<h1>access denied</h1>
<p>
  You have tried to access a resource
  <ng-container *ngIf="urlTenantId"
    >for <b>{{ urlTenantId }}</b></ng-container
  >
  but you do not have the required access.
</p>
<p>
  If you need access<ng-container *ngIf="urlTenantId">
    to <b> {{ urlTenantId }}</b></ng-container
  >, please contact your Service Desk.
</p>

<button
  class="action-btn"
  *ngIf="urlTenantId && isAuthenticated$ | async"
  mat-flat-button
  data-test-btn="home"
  (click)="goToHome()"
>
  home
</button>
<button *ngIf="isAuthenticated$ | async" mat-flat-button data-test-btn="logout" (click)="logout()">log out</button>
