import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './app.config';

const requestInit: RequestInit = {
  cache: 'no-cache',
};

fetch('/assets/app-config/config.json', requestInit)
  .then((res) => res.json())
  .then((config) => {
    console.info('Intranet running with config:', config);

    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
