<mat-form-field class="search-input">
  <input
    matInput
    id="searchProperties"
    [required]="required"
    [matAutocomplete]="auto"
    [placeholder]="'shared.proca.propertySearch.placeholder' | translation"
    [formControl]="propertySearch"
  />

  <div class="suffix-area" *ngIf="!readOnly && !disabled" matSuffix>
    <mat-spinner *ngIf="pending" [diameter]="18" [strokeWidth]="3"></mat-spinner>
    <button mat-icon-button (click)="clearPropertySearch()">
      <mat-icon svgIcon="ak-close"></mat-icon>
    </button>
  </div>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event.option.value)">
    <mat-option *ngFor="let property of properties" [value]="property">
      {{ getCustomPropertyName(property) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div *ngIf="selectedProperties?.length" class="selected-options-container">
  <button
    mat-flat-button
    data-test-selected-property
    *ngFor="let selectedProperty of selectedProperties"
    [disabled]="disabled"
    (click)="onDeselect(selectedProperty)"
  >
    <span>{{ selectedProperty.name }}</span>
    <mat-icon svgIcon="ak-close" *ngIf="!disabled"></mat-icon>
  </button>
</div>
