import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  styleUrls: ['file-type.component.scss'],
  template: `
    <ng-template #selectedFiles let-files="files" let-removeFileFunction="removeFileFunction">
      <ng-container *ngFor="let file of files; let i = index">
        <app-upload-file-preview class="file-preview" [file]="file" [fileTooLarge]="fileIsTooLarge(file)">
          <button (click)="removeFileFunction(i)" mat-icon-button>
            <mat-icon svgIcon="ak-close"></mat-icon></button></app-upload-file-preview
      ></ng-container>
    </ng-template>

    <ak-input-file
      [formControl]="formControl"
      [maxFiles]="props?.maxFiles"
      [formlyAttributes]="field"
      [selectedFilesTemplate]="selectedFiles"
      [acceptString]="props?.acceptedTypes"
      [title]="props?.title | translation"
    ></ak-input-file>
  `,
})
export class FormlyFileComponent extends FieldType<FieldTypeConfig> {
  fileIsTooLarge(file: File): boolean {
    const maxFileSize = this.props.maxFileSize;
    return maxFileSize && file.size > maxFileSize * 1048576;
  }
}
