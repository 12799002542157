<ng-container
  *ngIf="
    authService.loggedIn && (translations$ | async) !== null && (headerConfig$ | async) as headerConfig;
    else spinner
  "
>
  <ng-container *ngIf="!headless">
    <mat-menu #tenantMenu="matMenu">
      <ng-container *ngFor="let tenant of tenantService.userAllowedTenants$ | async">
        <button mat-menu-item (click)="tenantService.activate(tenant.id)">
          <mat-icon svgIcon="ak-check" *ngIf="(tenantService.activeTenant$ | async)?.id === tenant.id"
            >(active)</mat-icon
          >
          <span *ngIf="(tenantService.activeTenant$ | async)?.id !== tenant.id" class="menu-reserved-icon-space">
          </span>
          <span> {{ tenant.displayName }} </span>
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #debuggerMenu="matMenu">
      <button mat-menu-item (click)="showAppInfo()">
        <mat-icon>info_outline</mat-icon>
        <span>{{ 'core.menu.information' | translation }}</span>
      </button>

      <button mat-menu-item (click)="debugTranslationToggle()">
        <mat-icon svgIcon="ak-globe-web"></mat-icon>
        <span>{{ 'core.menu.translations' | translation }}</span>
      </button>

      <button mat-menu-item aria-label="contacts" (click)="goToContacts()">
        <mat-icon svgIcon="ak-contacts"></mat-icon>
        <span>{{ 'core.menu.contacts' | translation }}</span>
      </button>
    </mat-menu>

    <mat-menu #menu="matMenu" class="header-menu">
      <button
        mat-menu-item
        *ngIf="userProfile$ | async as userData"
        (click)="openURL(headerConfig.profile)"
        [disabled]="!userData || !headerConfig.profile"
      >
        <mat-icon svgIcon="ak-person"></mat-icon>
        <span>{{ userData?.name }}</span>
      </button>

      <!-- if multiple options are available we create a menu for selecting the tenant -->
      <button
        *ngIf="(userAllowedTenants$ | async)?.length > 1; else singleTenant"
        mat-menu-item
        [matMenuTriggerFor]="tenantMenu"
      >
        <span> tenant ({{ (tenantService.activeTenant$ | async)?.displayName }})</span>
      </button>

      <!-- if only one tenant option is available we display it as a disabled menu item -->
      <ng-template #singleTenant>
        <span mat-menu-item [disabled]="true"> tenant ({{ (tenantService.activeTenant$ | async)?.displayName }})</span>
      </ng-template>

      <button
        *ngIf="headerConfig.calendar"
        mat-menu-item
        aria-label="calendar"
        (click)="openURL(headerConfig.calendar)"
      >
        <mat-icon svgIcon="ak-event"></mat-icon>
        <span>{{ 'core.menu.calendar' | translation }}</span>
      </button>

      <button
        *ngIf="headerConfig.deskBooking"
        mat-menu-item
        aria-label="desk booking"
        (click)="openURL(headerConfig.deskBooking)"
      >
        <mat-icon svgIcon="ak-reception"></mat-icon>
        <span>{{ 'core.menu.desk-booking' | translation }}</span>
      </button>

      <button *ngIf="!fullWidth" mat-menu-item aria-label="fullscreen" (click)="toggleFullscreen()">
        <mat-icon svgIcon="ak-fullscreen"></mat-icon>
        <span>{{ 'core.menu.fullscreen' | translation }}</span>
      </button>

      <button *ngIf="fullWidth" mat-menu-item aria-label="fullscreen exit" (click)="toggleFullscreen()">
        <mat-icon svgIcon="ak-fullscreen-exit"></mat-icon>
        <span>{{ 'core.menu.fullscreen-exit' | translation }}</span>
      </button>

      <button
        *ngIf="headerConfig.remoteDesktop && (showDebuggerMenu$ | async)"
        mat-menu-item
        (click)="openURL(headerConfig.remoteDesktop)"
      >
        <mat-icon svgIcon="ak-remote-desk-booking"></mat-icon>
        <span>{{ 'core.menu.remote-desktop' | translation }}</span>
      </button>

      <button *ngIf="showDebuggerMenu$ | async" mat-menu-item [matMenuTriggerFor]="debuggerMenu">
        <mat-icon svgIcon="ak-gear"></mat-icon>
        <span>{{ 'core.menu.debug' | translation }}</span>
      </button>

      <button mat-menu-item (click)="showSystemInfos()">
        <mat-icon>info_outline</mat-icon>
        <span>{{ 'core.menu.about' | translation }}</span>
      </button>

      <button mat-menu-item (click)="onLogout()">
        <mat-icon svgIcon="ak-exit-app"></mat-icon>
        <span>{{ 'core.menu.logout' | translation }}</span>
      </button>
    </mat-menu>
    <mat-sidenav-container [ngClass]="{ 'fixed-height': fixedHeight$ | async }">
      <mat-sidenav #drawer [fixedInViewport]="true" [autoFocus]="false">
        <div class="sidenav-header">
          <span
            *ngIf="headerConfig.title"
            (click)="goHome()"
            (keydown.enter)="goHome()"
            [tabindex]="0"
            class="app-name"
            >{{ headline }}</span
          >
          <span class="spacer"></span>
          <button mat-icon-button aria-label="Close Sidenav" (click)="drawer.toggle()">
            <mat-icon svgIcon="ak-close"></mat-icon>
          </button>
        </div>

        <ak-menu
          *ngIf="menuItems$ | async as menuItems"
          [menuItems]="menuItems"
          (menuClicked)="drawer.close()"
        ></ak-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <main>
          <mat-toolbar [attr.data-selector]="'toolbar'">
            <mat-toolbar-row>
              <button mat-icon-button class="header-button" aria-label="Toggle sidenav" (click)="drawer.toggle()">
                <mat-icon svgIcon="ak-menu"></mat-icon>
              </button>
              <span
                *ngIf="headerConfig.title"
                (click)="goHome()"
                (keydown.enter)="goHome()"
                [tabindex]="0"
                class="app-name"
                >{{ headline }}</span
              >
              <span class="spacer"></span>
              <button
                data-test-header-btn-language
                mat-icon-button
                class="header-button"
                aria-label="Change Language"
                (click)="selectLanguage()"
              >
                <mat-icon svgIcon="ak-globe-web"></mat-icon>
              </button>
              <button
                *ngIf="headerConfig.help"
                data-test-header-btn-service-desk
                mat-icon-button
                class="header-button"
                aria-label="Support"
                (click)="openURL(headerConfig.help)"
              >
                <mat-icon svgIcon="ak-support"></mat-icon>
              </button>
              <button
                *ngIf="headerConfig.search"
                data-test-header-btn-search
                mat-icon-button
                class="header-button"
                aria-label="Search"
                (click)="openSearch()"
              >
                <mat-icon svgIcon="ak-search" class="large-icon"></mat-icon>
              </button>
              <ak-user-picture
                [svgIcon]="'ak-person'"
                [menuTriggerFor]="menu"
                [src]="(userProfile$ | async)?.picture"
              ></ak-user-picture>
            </mat-toolbar-row>
          </mat-toolbar>
          <div
            [ngClass]="{ container: true, 'container-constrained-height': fixedHeight$ | async }"
            [class.full-width]="fullWidth"
          >
            <div *ngIf="!breadcrumbless">
              <app-breadcrumbs [attr.data-selector]="'breadcrumbs'" class="breadcrumbs"></app-breadcrumbs>
            </div>
            <div class="content-router-outlet">
              <router-outlet></router-outlet>
            </div>
          </div>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>

  <ng-container *ngIf="headless">
    <div class="container-fluid">
      <div class="row" *ngIf="!breadcrumbless">
        <app-breadcrumbs [attr.data-selector]="'breadcrumbs'" class="breadcrumbs col-12"></app-breadcrumbs>
      </div>
      <div class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
