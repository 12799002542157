import { AssetSelectOption } from './interfaces';
import { SuffixType } from '../../../shared/domain/property-catalog/models/property.variables';
import { GeoOptions } from '../store/asset-detail/asset-detail-state';
import { Observable } from 'rxjs';
import { TableConfigEntity, TableUpdateConfigEntity } from './asset-overview.interfaces';
import { MaterialSelectOption } from '../../../shared/functional/material-core';
import { Action } from './asset-action.interfaces';

export interface UISchemaConfig {
  fields: FieldDefinition[];
  type: TypeDescriptor;
  tables?: TableConfigEntity[];
}

export interface TypeDescriptor {
  assetCategories: string[];
  assetCategoryKeys: string[];
  instancePath: string;
  typeName: string;
  typeNamePlural: string;
  helpTextKey: string;
  dictionaryKey: string;
  dictionaryKeyPlural: string;
  links?: [
    {
      name: string;
      _link: Action;
    }
  ];
}

export interface BooleanConfig {
  allowNull: boolean;
}

export interface StringFieldConfig {
  validationRegularExpression: string;
}

export interface SelectFieldConfig {
  cardinality: 'multiple' | 'single';
  selectableValues: AssetSelectOption[];
}

export interface FileFieldConfig {
  maxFiles: number;
  titleKey: string;
}

export interface NumberFieldConfig {
  allowNegative: boolean;
}

export interface NumberFieldDisplayConfig {
  decimalPlaces: number;
}

export interface RelationshipOption {
  name: string;
  objectId: string;
  linked: boolean;
}

export interface RelationshipConfig {
  cardinality: 'multiple' | 'single';
  _links: {
    selectEdit: Action;
    selectCreate: Action;
  };
  options?: AssetSelectOption[]; // added by FE
}

export enum FieldType {
  string = 'String',
  selection = 'selection',
  date = 'Date',
  geo = 'selection-geo',
  relationship = 'relationship',
  number = 'Number',
  boolean = 'boolean',
  file = 'file',
  hidden = 'hidden',
}

export interface FormlyDataSourceConfig {
  [FieldType.geo]?: Observable<GeoOptions>;
}

interface DisplayConfig<T> {
  displayKey?: string;
  showEmpty: boolean;
  suffix?: SuffixType;
  showInCountries: number[] | null;
  typeSpecificConfig: T;
}

interface UpdateConfig<T> {
  defaultValue: string;
  creatable: boolean;
  creatableInCountries: number[] | null;
  editable: boolean;
  editableInCountries: number[] | null;
  required: boolean;
  typeSpecificConfig: T;
}

export interface FieldDefinition {
  group: number;
  name: string;
  helpTextKey: string;
  type: FieldType;
  displayConfig: DisplayConfig<{ [key: string]: any }>;
  updateConfig: UpdateConfig<{ [key: string]: any }>;
  dictionaryKey: string;
}

export interface InlineFieldDefinition extends FieldDefinition {
  editable: boolean;
}

export interface StringFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<StringFieldConfig>;
}

export function isStringFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is StringFieldDefinition {
  return fieldDefinition.type === FieldType.string;
}

export interface RelationshipFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<RelationshipConfig>;
}

export function isRelationshipFieldDefinition(
  fieldDefinition: FieldDefinition
): fieldDefinition is RelationshipFieldDefinition {
  return fieldDefinition.type === FieldType.relationship;
}

export interface SelectFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<SelectFieldConfig>;
}

export interface TableSelectFieldDefinition extends TableUpdateConfigEntity {
  updateConfig: UpdateConfig<SelectFieldConfig>;
}

export function isSelectFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is SelectFieldDefinition {
  return fieldDefinition.type === FieldType.selection;
}

export function isTableSelectFieldDefinition(
  fieldDefinition: TableUpdateConfigEntity
): fieldDefinition is TableSelectFieldDefinition {
  return fieldDefinition.type === FieldType.selection;
}

export interface FileFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<FileFieldConfig>;
}

export interface DateFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<{}>;
}

export function isDateFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is DateFieldDefinition {
  return fieldDefinition.type === FieldType.date;
}

export interface GeoFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<{}>;
}

export function isGeoFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is GeoFieldDefinition {
  return fieldDefinition.type === FieldType.geo;
}

export interface NumberFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<NumberFieldConfig>;
  displayConfig: DisplayConfig<NumberFieldDisplayConfig>;
}

export interface NumberFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<NumberFieldConfig>;
}

export function isNumberFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is NumberFieldDefinition {
  return fieldDefinition.type === FieldType.number;
}

export interface BooleaFieldDefinition extends FieldDefinition {
  updateConfig: UpdateConfig<BooleanConfig>;
}

export function isBooleanFieldDefinition(fieldDefinition: FieldDefinition): fieldDefinition is BooleaFieldDefinition {
  return fieldDefinition.type === FieldType.boolean;
}
