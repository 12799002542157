<!--
Preload has to be set to "metadata" or "auto" if we want to react to the loadeddata event.
If we set it to "none" the loadeddata event will not be triggered and the player will stay hidden.
The video player will show an "i" icon until the video is loaded. This is why we hide it in the beginning.

Since we do not get any preview image from the backend we have to set the poster attribute to one of the
first image of the video. That is why we add "#t=0.1" to the end of the src attribute. This is a workaround
for Safari.
-->

<video
  controls
  oncontextmenu="return false;"
  color="accent"
  [controls]="controls"
  [attr.poster]="videoData.img ? videoData.img : ''"
  preload="metadata"
  width="100%"
  height="100%"
  (loadeddata)="videoIsLoaded()"
  (error)="videoLoadHasFailed()"
  [src]="videoData.sources[0].src + '#t=0.1'"
>
  <ng-container *ngFor="let source of videoData.sources; let idx = index">
    <source src="{{ source.src }}" type="{{ source.type }}" media="{{ source.media }}" />

    <ng-container *ngIf="idx <= videoData.sources.length">
      <!-- this will let us play Quicktime files(.mov) as MP4 -->
      <source src="{{ source.src }}" type="video/mp4" />
      {{ 'core.lightbox.browserNotSupportVideo' | translation }}
    </ng-container>
  </ng-container>
</video>
