import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { StorageObject } from './models/storage.interfaces';
import * as fromInterceptors from './interceptors';
import * as fromServices from './services';
import { auth0ClientFactory } from './services/auth.service';
import * as fromCommon from './common';
import { IconService } from './services/icon.service';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { GraphQLModule } from '../features/staff-app/apollo.config';
import { APP_CONFIG } from '../../app.config';
import { AuthClientInjectable } from './injection-tokens/auth-client.token';

export function initializeApp(iconService: IconService): () => Observable<[string[], string[]]> {
  return () => iconService.registerIcons();
}

export function windowFactory(): Window {
  return window;
}

export function storageFactory(): StorageObject {
  return {
    localStore: localStorage,
    sessionStore: sessionStorage,
  };
}

export function getBaseHref() {
  const segments = location.pathname.split('/');

  return segments[1] === 't' ? `/${segments[1]}/${segments[2]}` : '/';
}

export function getTenantId() {
  const segments = location.pathname.split('/');

  return segments[1] === 't' ? segments[2] : null;
}

export const URL_TENANT_ID = new InjectionToken('urlTenantId');
// export const STORAGE_KEY_TENANT_ID = new InjectionToken('storageKeyTenantId');
export const STORAGE_OBJECT = new InjectionToken('storageObject');
export const WINDOW_OBJECT = new InjectionToken<Window>('windowObject');

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GraphQLModule, // Apollo initialisation doesn't work properly in the lazy-loaded module
  ],
  declarations: [],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [IconService],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        fromInterceptors.interceptors,
        fromCommon.CustomUrlSerializerProvider,
        fromServices.GlobalErrorHandlerProvider,
        { provide: WINDOW_OBJECT, useFactory: windowFactory },
        { provide: STORAGE_OBJECT, useFactory: storageFactory },
        {
          provide: AuthClientInjectable,
          useFactory: auth0ClientFactory,
          deps: [WINDOW_OBJECT, APP_CONFIG],
        },
        { provide: APP_BASE_HREF, useFactory: getBaseHref },
        { provide: URL_TENANT_ID, useFactory: getTenantId },
      ],
    };
  }
}

function throwIfAlreadyLoaded(parentModule: CoreModule, moduleName: string) {
  if (parentModule) {
    const msg = `${moduleName} has already been loaded. Import Core modules in the AppModule only.`;
    throw new Error(msg);
  }
}
