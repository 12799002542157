<mat-form-field class="filter-input">
  <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
    <input
      matStartDate
      data-test-id="begin"
      formControlName="begin"
      [placeholder]="'core.forms.dateRangePicker.begin.placeholder' | translation"
    />
    <input
      matEndDate
      data-test-id="end"
      formControlName="end"
      [placeholder]="'core.forms.dateRangePicker.end.placeholder' | translation"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
