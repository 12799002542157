import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AkeliusIDToken } from '../models/akelius-id-token.model';
import { RequestHelpers } from '../helpers/request.helpers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!RequestHelpers.isIntranetRequest(req, this.appConfig)) {
      return next.handle(req);
    }

    return this.authService.getTokenSilently$().pipe(
      switchMap((token: string) => {
        return this.authService
          .getIdToken$()
          .pipe(map<AkeliusIDToken, [string, AkeliusIDToken]>((idToken: AkeliusIDToken) => [token, idToken]));
      }),
      mergeMap(([token, idToken]: [string, AkeliusIDToken]) => {
        const reqWithHeaders = req.clone({
          withCredentials: false,
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'x-akelius-id-token': idToken?.__raw, // looks hacky but approved by Andreas Grimm
          },
        });

        return next.handle(reqWithHeaders);
      }),
      catchError((err) => throwError(err)),
    );
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
