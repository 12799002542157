import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotFoundError } from '../common/not-found-error';
import { BadInputError } from '../common/bad-input-error';
import { NotAuthenticatedError } from '../common/not-authenticated-error';
import { AppError } from '../common/app-error';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NotAuthorizedError } from '../common/not-authorized-error';
import { ServiceUnavailableError } from '../common/service-unavailable-error';
import { ServiceReadonlyError } from '../common/service-readonly-error';

export enum MappedErrorTypes {
  'ERROR' = 'ERROR',
  'READONLY' = 'READONLY',
}

@Injectable()
export class DataService {
  static serviceNameMappings: { [key: string]: string } = {
    BSC: 'Business School',
    PRC: 'Property Catalog',
    OSA: 'Staff',
    SMB: 'Smart Buildings',
    DIC: 'Dictionary',
    DOS: 'Document',
    MED: 'Media',
    RES: 'Responsible Persons',
  };

  constructor() {}

  handleError(error: HttpErrorResponse) {
    let mappedError: Error;

    if (!error.hasOwnProperty('status')) {
      // this isn't a http error so return the original
      mappedError = error;
    } else if (error.status === 400) {
      mappedError = new BadInputError(error);
    } else if (error.status === 401) {
      mappedError = new NotAuthenticatedError(error);
    } else if (error.status === 403) {
      mappedError = new NotAuthorizedError(error);
    } else if (error.status === 404) {
      mappedError = new NotFoundError(error);
    } else if (error.status === 502) {
      mappedError = DataService.getRemoteServiceError(error);
    } else {
      mappedError = new AppError(error);
    }

    return throwError(() => mappedError);
  }

  private static getRemoteServiceError(error: HttpErrorResponse) {
    // The backend will send us an errorCode for all 502 errors with the 3 letter service code and the type of error
    // joined with a :
    // e.g. PRC:READONLY
    // currently the only errors types are READONLY and ERROR

    const errorText = error.error.errorCode;
    const [serviceCode, errorType] = errorText ? errorText.split(':') : [];
    const serviceName = DataService.serviceNameMappings[serviceCode];

    if (!serviceName) {
      // we don't know what the service is so handle it with the normal Sentry popup
      return new AppError(error);
    } else {
      return errorType === MappedErrorTypes.READONLY
        ? new ServiceReadonlyError(serviceName)
        : new ServiceUnavailableError(serviceName);
    }
  }

  // Takes a params object and returns corresponding HttpParams object as required by HttpClient
  protected toHttpParams(params: { [key: string]: any }) {
    return Object.getOwnPropertyNames(params).reduce((p, key) => p.set(key, params[key]), new HttpParams());
  }

  protected urlFromTemplate(urlTemplate: string, params: { [key: string]: string }) {
    return urlTemplate.replace(/\{\w+\}/g, function (placeholder) {
      const paramName = placeholder.replace(/\{(\w+)\}/, '$1'); // placeholder without brackets
      return params[paramName] != null ? params[paramName] : paramName;
    });
  }

  protected deleteParamWithNullValue(params: { [key: string]: any }): { [key: string]: any } {
    return Object.keys(params).reduce((cleanParams, key) => {
      if (params[key] || params[key] === 0) {
        cleanParams[key] = params[key];
      }
      return cleanParams;
    }, {} as { [key: string]: any });
  }
}
