import { FormlyFieldConfig } from '@ngx-formly/core';

export function dataE2EHooks(field: FormlyFieldConfig) {
  const attributes = { ...(field.props?.attributes || {}) };
  if (field.key) {
    attributes['data-test-id'] = field.key.toString();
  }

  field.props = {
    ...(field.props || {}),
    attributes,
  };
}
