import { Injectable } from '@angular/core';

import { ItemNode, TreeNode } from '../models';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TreeDatabase<N, G> {
  dataChange = new BehaviorSubject<ItemNode<N, G>[]>([]);

  get dataChange$(): Observable<ItemNode<N, G>[]> {
    return this.dataChange;
  }

  get data(): ItemNode<N, G>[] {
    return this.dataChange.value;
  }

  initialize(treeDefinition: TreeNode<N, G> | TreeNode<N, G>[]) {
    // Build the tree nodes from Json object. The result is a list of `ItemNode<N, G>` with nested
    //     file node as children.
    treeDefinition = (treeDefinition && Array.isArray(treeDefinition) ? treeDefinition : [treeDefinition]) as TreeNode<
      N,
      G
    >[];

    const data: ItemNode<N, G>[] = treeDefinition.reduce((array: ItemNode<N, G>[], rootNode: TreeNode<N, G>) => {
      return [...array, ...this.buildFileTreeRoot(rootNode, 0)];
    }, []);

    // Notify the change.
    this.dataChange.next(data);
  }

  buildFileTreeRoot(inputNode: TreeNode<N, G>, level: number): ItemNode<N, G>[] {
    const assetName = [inputNode.id];
    const itemNode = new ItemNode<N, G>();

    itemNode.data = inputNode.data;
    itemNode.isGroup = inputNode.isGroup;
    itemNode.id = inputNode.id;
    itemNode.parentIds = assetName;
    itemNode.children = this.buildFileTree(inputNode, level + 1, assetName);

    return [itemNode];
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `ItemNode<N, G>`.
   */
  buildFileTree(inputNode: TreeNode<N, G>, level: number, parentIds: string[]): ItemNode<N, G>[] {
    const groups: TreeNode<N, G>[] = inputNode.children ? inputNode.children.filter((child) => child.isGroup) : [];
    const directNodeChildren: TreeNode<N, G>[] = inputNode.children
      ? inputNode.children.filter((child) => !child.isGroup)
      : [];

    const groupItems = groups.map((group) => {
      const itemNode = new ItemNode<N, G>();
      itemNode.data = group.data;
      itemNode.isGroup = true;
      itemNode.id = group.id;
      itemNode.parentIds = [];
      itemNode.children = this.buildFileTree(group, level + 1, parentIds);
      return itemNode;
    });

    const childItems = directNodeChildren.map((child) => {
      const itemNode = new ItemNode<N, G>();

      itemNode.data = child.data;
      itemNode.id = child.id;
      itemNode.isGroup = false;
      itemNode.parentIds = [...parentIds, itemNode.id];
      itemNode.children = this.buildFileTree(child, level + 1, [...parentIds, itemNode.id]);
      return itemNode;
    });

    return [...groupItems, ...childItems];
  }
}
