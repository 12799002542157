export class ServiceReadonlyError extends Error {
  serviceName = '';

  constructor(serviceName: string) {
    super(serviceName + ' service is readonly');
    this.name = ServiceReadonlyError.name;
    Object.setPrototypeOf(this, ServiceReadonlyError.prototype);
    this.serviceName = serviceName;
  }
}
