export const groupsToCollapse: { [key: string]: string } = {
  'it department': 'Technology',
  it: 'Technology',
  'it-department': 'Technology',
  'technology department': 'Technology',
  technology: 'Technology',

  'architecture and design department': 'Architecture',
  'architecture department': 'Architecture',
  architecture: 'Architecture',

  'property department': 'Property',
  property: 'Property',

  'customer department': 'Customer',
  customer: 'Customer',

  'finance department': 'Finance',
  finance: 'Finance',

  'procurement department': 'Procurement',
  procurement: 'Procurement',
  'logistic department': 'Procurement',
  logistic: 'Procurement',

  'education department': 'Education',
  education: 'Education',
  'akelius språkkurs': 'Education',

  'construction department': 'Other Departments',
  construction: 'Other Departments',
  'business department': 'Other Departments',
  business: 'Other Departments',
  'staff department': 'Other Departments',
  staff: 'Other Departments',
};

export class GoogleAnalyticsHelpers {
  static setDataLayerUser(userDetail: { department: string; country: string }) {
    if (window) {
      const dataLayer = ((<any>window)['dataLayer'] = (<any>window)['dataLayer'] || []);
      // noinspection EmptyCatchBlockJS
      try {
        dataLayer.push({
          department: this.getNonIdentifiableGroup(userDetail['department'], userDetail['country']),
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  static setDataLayerDocument(documentTitle: string) {
    if (window) {
      const dataLayer = ((<any>window)['dataLayer'] = (<any>window)['dataLayer'] || []);
      // noinspection EmptyCatchBlockJS
      try {
        dataLayer.push({
          event: 'documentDownload',
          documentValue: documentTitle,
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  // Avoid storing data in a way where the groups are too small for GDPR compliance
  static getNonIdentifiableGroup(department: string, country: string) {
    if (groupsToCollapse[department.toLowerCase()]) {
      return groupsToCollapse[department.toLowerCase()];
    } else {
      return `Operations ${country}`;
    }
  }
}
