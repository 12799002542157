import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingIndicatorService {
  private loading = new BehaviorSubject(false);
  loading$ = this.loading.asObservable();

  constructor() {}

  setLoading(loading: boolean) {
    this.loading.next(loading);
  }
}
