import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialCoreModule } from '../material-core/material-core.module';
import { TreeComponent } from './components/tree/tree.component';
import { RippleModule } from '@akelius/angular-ui-kit';

@NgModule({
  declarations: [TreeComponent, fromComponents.components],
  imports: [CommonModule, ReactiveFormsModule, MaterialCoreModule, RippleModule],
  exports: [fromComponents.components],
})
export class TreeModule {}
