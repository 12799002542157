import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '../../../../../core/stores/store.service';
import { Observable } from 'rxjs';
import { AutocompleteItem } from '../../components';

export interface AutocompleteState {
  loading: boolean;
  loaded: boolean;
  items: AutocompleteItem[];
  searchText: string | null;
}

export const initialState: AutocompleteState = {
  loading: false,
  loaded: false,
  items: [],
  searchText: null,
};

@Injectable({
  providedIn: 'root',
})
export class AutoCompleteStore extends Store<AutocompleteState> implements OnDestroy {
  constructor() {
    super(initialState);
  }

  get items$(): Observable<AutocompleteItem[]> {
    return this.select<AutocompleteItem[]>('items');
  }

  get loading$(): Observable<boolean> {
    return this.select<boolean>('loading');
  }
}
