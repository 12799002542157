export interface TreeNode<N, G> {
  id: string;
  children?: TreeNode<N, G>[];
  isGroup: boolean;
  data: N | G;
}

// Internal item nodes used by tree
export class ItemNode<N, G> {
  children: ItemNode<N, G>[];
  id: string;
  isGroup: boolean;
  data: any;
  parentIds: any[];
}

/** Flat to-do item node with expandable and level information */
export class ItemFlatNode<N, G> {
  id: string;
  level: number;
  expandable: boolean;
  isGroup: boolean;
  data: any;
  childCount: number;
  parentIds: string[];
}
