import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-picture-asset',
  styles: [
    `
      @use '@akelius/material-theme/src/style/sizes';

      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: sizes.$large;
      }
    `,
  ],
  template: ` <img [src]="to?.imgUrl?.href" /> `,
})
export class FormlyPictureAssetComponent extends FieldType<FieldTypeConfig> {}
