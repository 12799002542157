import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

// rxjs
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-field-debounced-text-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormlyFieldDebouncedTextComponent),
      multi: true,
    },
  ],
  template: `
    <mat-form-field>
      <mat-label>{{ to.label }}</mat-label>
      <input
        matInput
        [value]="interimValue | async"
        [formlyAttributes]="field"
        (keyup)="searchTerm$.next($event.target['value'])"
      />
      <button
        *ngIf="(interimValue | async) !== '' && (interimValue | async) !== null"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onClearClicked()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  `,
})
export class FormlyFieldDebouncedTextComponent implements OnDestroy, ControlValueAccessor {
  @Input() to: NonNullable<FormlyFieldConfig['props']>;
  @Input() field: FormlyFieldConfig;

  searchTerm$ = new Subject<string>();
  interimValue = new BehaviorSubject<string | null>('');
  onChange: Function;
  onTouch: Function;

  writeValue(value: string) {
    this.searchTerm$.next(value);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  constructor() {
    this.searchTerm$
      .pipe(distinctUntilChanged(), tap({ next: (val) => this.interimValue.next(val) }), debounceTime(800))
      .subscribe({
        next: (value) => {
          this.onChange(value);
        },
      });
  }

  ngOnDestroy(): void {
    this.searchTerm$.complete();
  }

  onClearClicked() {
    this.searchTerm$.next(''); // we update the search term since there may be outstanding debounced updates
    this.onChange('');
  }
}

@Component({
  selector: 'formly-field-custom-input',
  template: `<formly-field-debounced-text-input
    [to]="props"
    [field]="field"
    [formControl]="formControl"
  ></formly-field-debounced-text-input>`,
})
export class FormlyFieldTextFilterComponent extends FieldType<FieldTypeConfig> {}
