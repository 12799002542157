import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';

import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

import { FieldSuffixType } from '../models';

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'formly-field-date-picker',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
  template: `
    <input
      matInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [matDatepicker]="datePicker"
      [errorStateMatcher]="errorStateMatcher"
      [matDatepickerFilter]="props.datepickerOptions.filter"
      (click)="openPicker()"
    />
    <ng-template #matSuffix>
      <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
    </ng-template>
    <mat-datepicker #datePicker></mat-datepicker>
  `,
})
export class FormlyDatePickerComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(MatInput) formFieldControl: MatInput;
  @ViewChild('datePicker') datePicker: MatDatepicker<HTMLElement>;
  @ViewChild('matSuffix') private _matSuffix: TemplateRef<HTMLElement>;

  fieldSuffixType = FieldSuffixType;

  ngAfterViewInit() {
    if (this._matSuffix) {
      this.props.suffix = this._matSuffix;
    }
  }

  get addonRightText(): string {
    return this.props.addonRight.text;
  }

  get showHelpIcon(): boolean {
    return this.props.addonRight?.type === this.fieldSuffixType.helpIcon;
  }

  openPicker() {
    this.datePicker.open();
  }

  clearField(event: MouseEvent) {
    event.stopPropagation();
    this.formControl.setValue(null);
  }
}
