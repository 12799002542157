import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, @Inject(APP_CONFIG) private config: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBackendRequest(req.url)) {
      return this.authService.getTokenSilently$().pipe(
        mergeMap((token) => {
          const tokenReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(tokenReq);
        }),
        catchError((err) => throwError(err))
      );
    } else {
      return next.handle(req);
    }
  }

  private isBackendRequest(url: string): boolean {
    const staffAppConfig = this.config.staffApp;
    return (
      url.indexOf(staffAppConfig.backend.rest) >= 0 ||
      url.indexOf(staffAppConfig.backend.gql) >= 0 ||
      url.indexOf(staffAppConfig.media) >= 0
    );
  }
}
