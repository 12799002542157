import { Inject, Injectable } from '@angular/core';
import { RelationshipOption } from '../models/ui-schema.interfaces';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../core/services/data.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { paramIncludeInactive } from '../data-config/property-api-config';
import { Action } from '../models/asset-action.interfaces';
import { AssetSelectOption } from '../models/interfaces';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class RelationshipService extends DataService {
  private propertyApiRoot = this.appConfig.propertyCatalogApiRoot;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) {
    super();
  }

  // context ID is either the ID of the asset being edited or of the parent of the asset being created
  getRelationshipOptions(action: Action, create: boolean, contextId: string): Observable<AssetSelectOption[]> {
    const endpoint = action.href;
    const param = create ? 'parentid' : 'id';

    const fullUrl = this.urlFromTemplate(
      `${this.appConfig.backend}${this.propertyApiRoot}${endpoint}?${paramIncludeInactive}`,
      { [param]: contextId }
    );

    return this.http.get<RelationshipOption[]>(fullUrl).pipe(
      map((result): AssetSelectOption[] =>
        result.map((option: RelationshipOption) => ({
          title: option.name,
          value: option.objectId,
          dictionaryKey: null,
          selectableInCountries: [],
        }))
      ),
      catchError(this.handleError)
    );
  }
}
