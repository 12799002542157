import { AbstractControl, ValidationErrors } from '@angular/forms';
import {ValidatorOption} from '@ngx-formly/core/lib/models';

import { FormlyFieldType } from '../models';

export function individualFileUploadSizeValidatorFormly(maxSizeInMb: number): Function {
  return (control: AbstractControl): boolean => {
    const maxSizeInBytes = maxSizeInMb * 1048576;

    if (control.value) {
      const oversizedFiles = control.value.filter((file: File) => file.size > maxSizeInBytes);

      return oversizedFiles.length === 0;
    }
    return true;
  };
}

export function EmailValidator(control: AbstractControl): ValidationErrors | null {
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return !control.value || regex.test(control.value) ? null : { email: true };
}

export function assetSizeValidatorFormly(maxAssets: number): Function {
  return (control: AbstractControl): boolean => {
    if (maxAssets && control.value) {
      return control.value.length === maxAssets;
    }
    return true;
  };
}

export const validators: ValidatorOption[] = [
  {
    name: FormlyFieldType.email,
    validation: EmailValidator,
  },
];
