import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as declarations from '.';
import { MaterialCoreModule } from '../functional/material-core/material-core.module';
import { InputFileModule, MessageBoxModule } from '@akelius/angular-ui-kit';
import { TranslationModule } from '../functional/translation/translation.module';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const definitions = [...declarations.components, ...declarations.pipes, ...declarations.directives];

const modules = [
  MaterialCoreModule,
  MessageBoxModule,
  TranslationModule,
  RouterModule,
  InfiniteScrollModule,
  InputFileModule,
];

@NgModule({
  declarations: [...definitions],
  imports: [CommonModule, ...modules],
  exports: [...definitions, ...modules],
})
export class SharedCoreModule {}
