import { AuthInterceptorProvider } from './auth.interceptor';
import { LangInterceptorProvider } from './lang.interceptor';
import { RequestIdInterceptorProvider } from './request-id.interceptor';
import { TenantInterceptorProvider } from './tenant.interceptor';
import { NormalizeInterceptorProvider } from './normalize.interceptor';

export const interceptors: any[] = [
  LangInterceptorProvider,
  AuthInterceptorProvider,
  RequestIdInterceptorProvider,
  TenantInterceptorProvider,
  NormalizeInterceptorProvider,
];

export * from './auth.interceptor';
export * from './lang.interceptor';
export * from './request-id.interceptor';
export * from './tenant.interceptor';
