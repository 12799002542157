import { Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { Location } from '@angular/common';

@Injectable()
export class NormalizeInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // normalize the request URL if the request is for intranet or staff
    if (this.isIntranetRequest(req)) {
      req = req.clone({
        url: this.normalizeUrl(req.url),
      });
    }

    return next.handle(req);
  }

  private isIntranetRequest(req: HttpRequest<any>): boolean {
    return req.url.indexOf(this.appConfig.backend) !== -1;
  }

  // function to normalize a url using the HTML 5 URL api

  private normalizeUrl(url: string): string {
    return Location.stripTrailingSlash(url);
  }
}

export const NormalizeInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: NormalizeInterceptor,
  multi: true,
};
