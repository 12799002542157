import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum ActionButtonType {
  neutral = 'neutral',
  primary = 'primary',
  warn = 'warn',
  danger = 'danger',
  positive = 'positive',
}

export interface ActionButtonConfig<T> {
  text: string;
  type: ActionButtonType;
  icon?: string;
  disabled?: boolean;
  inProgress?: boolean;
  value: T;
  testId?: string;
}

export type ActionBarConfig<T> = ActionButtonConfig<T>[][];

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent<T> {
  classes: { [key in ActionButtonType]: string | null } = {
    [ActionButtonType.warn]: 'yellow',
    [ActionButtonType.danger]: 'red',
    [ActionButtonType.positive]: 'green',
    [ActionButtonType.primary]: null,
    [ActionButtonType.neutral]: null,
  };

  configSubject = new BehaviorSubject<ActionBarConfig<T>>([]);

  @Input() set buttons(config: ActionBarConfig<T>) {
    this.configSubject.next(config);
  }

  @Output() clicked = new EventEmitter<T>();

  getColor(button: ActionButtonConfig<T>) {
    return button.type === ActionButtonType.primary ? 'primary' : '';
  }

  getClass(button: ActionButtonConfig<T>) {
    return this.classes[button.type] ? this.classes[button.type] + ' action-button' : 'action-button';
  }
}
