import { ConfigOption } from '@ngx-formly/core';

import { types } from './types';
import { wrappers } from './wrappers';
import { extensions } from './extensions';
import { validators } from './validators';

export const CUSTOM_FORMLY_CONFIG: ConfigOption = {
  types,
  wrappers,
  extensions,
  validators,
  extras: {
    immutable: true,
    // lazyRender: false,
    // checkExpressionOn:  'modelChange',
    // resetFieldOnHide: false,
  },
};
