import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from './notification.service';
import { TranslationService } from './translation.service';
import { CheckVersionService } from './check-version.service';
import { NotificationMessageType } from '../../shared/core';
import { ActionButtonType } from '../../shared/core';
import { WINDOW_OBJECT } from '../core.module';

export interface Release {
  version: string;
  releaseNotesLink: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  showingReleasePopup = false;

  constructor(
    @Inject(WINDOW_OBJECT) private window: Window,
    private sanitizer: DomSanitizer,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private checkVersionService: CheckVersionService
  ) {}

  initialize() {
    this.checkVersionService.updateAvailable$.subscribe({
      next: (updateAvailable) => {
        if (updateAvailable && !this.showingReleasePopup) {
          this.showReloadNotification();
        }
      },
    });
  }

  showReloadNotification() {
    this.showingReleasePopup = true;

    this.notificationService
      .showCustomNotification<boolean>('release.heading.newReleaseAvailable', {
        message: this.translationService.getTranslationById('release.message.newRelease'),
        type: NotificationMessageType.info,
        buttons: [
          [
            {
              text: this.translationService.getTranslationById('release.button.continueWorking'),
              type: ActionButtonType.neutral,
              value: false,
            },
            {
              text: this.translationService.getTranslationById('release.button.updateNow'),
              type: ActionButtonType.primary,
              value: true,
            },
          ],
        ],
      })
      .subscribe({
        next: (result) => {
          if (result) {
            this.window.location.reload();
          }
          this.showingReleasePopup = false;
        },
      });
  }
}
