import { TemplateNameType } from './template-name.enum';

interface ModuleConfig {
  name: string;
  path: string;
}

type ModuleByTemplateNameMapping = {
  [key in TemplateNameType]: ModuleConfig;
};

export const MODULE_TEMPLATE_NAME_MAPPING: ModuleByTemplateNameMapping = {
  [TemplateNameType.vocabulary]: { name: 'vocabulary', path: 'vocabulary' },
  [TemplateNameType.pictures]: { name: 'pictures', path: 'pictures' },
  [TemplateNameType.document]: { name: 'document', path: 'document' },
  [TemplateNameType.video]: { name: 'videos', path: 'videos' },
  [TemplateNameType.iframe]: { name: 'iframe', path: 'iframe' },
  [TemplateNameType.proca_data_quality]: { name: 'proca-data-quality', path: 'proca-data-quality' },
  [TemplateNameType.properties]: { name: 'properties', path: 'property-catalog' },
  [TemplateNameType.costcenter]: { name: 'costcenter', path: 'property-catalog' },
};
