import { FormlyFieldConfig } from '@ngx-formly/core';

import { TranslationService } from '../../../../core/services/translation.service';
import { ValidationMessagesConfigEntity } from '../models/validation-message.interfaces';
import { FormlyFieldProps } from '@ngx-formly/core/lib/models/fieldconfig';

// todo: change to to props
export class ValidationMessagesExtension {
  private readonly validationMessagesConfig: ValidationMessagesConfigEntity[] = [
    {
      templateOption: 'required',
      validationMessageKey: 'required',
      dictionaryKey: 'core.forms.validation.requiredField',
      translationParam: null,
    },
    {
      templateOption: 'pattern',
      validationMessageKey: 'pattern',
      dictionaryKey: 'core.forms.validation.invalidFormat',
      translationParam: null,
    },
    {
      templateOption: 'maxFileSize',
      validationMessageKey: 'individualTotalFileSizeMb',
      dictionaryKey: 'core.forms.validation.maxFileSize',
      translationParam: 'maxFileSize',
    },
    {
      templateOption: 'maxAssets',
      validationMessageKey: 'maxAssets',
      dictionaryKey: 'core.forms.validation.maxAssets',
      translationParam: 'maxAssets',
    },
  ];

  constructor(private translationService: TranslationService) {}

  prePopulate(field: FormlyFieldConfig) {
    this.validationMessagesConfig.forEach((config: ValidationMessagesConfigEntity) => {
      if (field?.props?.[config.templateOption]) {
        field.validation = {
          ...(field.validation || {}),
          messages: {
            ...(field.validation?.messages || {}),
            [config.validationMessageKey]: this.getValidationMessage(config, field.props),
          },
        };
      }
    });
  }

  private getValidationMessage(config: ValidationMessagesConfigEntity, templateOptions: FormlyFieldProps): string {
    return config.translationParam
      ? this.translationService.getTranslationByIdAndParams(config.dictionaryKey, {
          [config.translationParam]: templateOptions[<keyof FormlyFieldProps>config.templateOption] as string,
        })
      : this.translationService.getTranslationById(config.dictionaryKey);
  }
}

export function registerValidationMessagesExtension(translationService: TranslationService) {
  return {
    extensions: [
      {
        name: 'validationMessages',
        extension: new ValidationMessagesExtension(translationService),
      },
    ],
  };
}
