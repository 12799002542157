import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PropertyDataService } from '../services/property-data.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '../../../core/services/notification.service';
import { NotFoundError } from '../../../core/common';
import { ContextFacadeService } from '../../../core/facades/context-facade.service';
import { AssetSummaryInfo } from '../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AssetDeepLinkGuard {
  constructor(
    private dataService: PropertyDataService,
    private router: Router,
    private notificationService: NotificationService,
    private contextFacade: ContextFacadeService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if url contains "permalink" we delete current context to not break breadcrumb
    if (state?.url.includes('permalink')) {
      this.contextFacade.resetContext();
    }

    return this.dataService.loadAssetInfoByObjectId(route.params['assetId']).pipe(
      switchMap((assetInfo: AssetSummaryInfo) => {
        // if the asset is a property it will only have 1 parent so we take the object itself
        if (assetInfo.parentObjectIds.length === 1) {
          return of({
            assetInfo,
            parentInfo: assetInfo, // parent and asset are the same
          });
        } else {
          // the parents are in reverse order so the last element is the root element
          // the last element is still the cost centre so we take the last but one to get property
          const propertyId = assetInfo.parentObjectIds[assetInfo.parentObjectIds.length - 2];
          return this.dataService.loadAssetInfoByObjectId(propertyId).pipe(
            map((parentInfo) => ({
              assetInfo,
              parentInfo,
            }))
          );
        }
      }),
      map((assetsData: { assetInfo: AssetSummaryInfo; parentInfo: AssetSummaryInfo }) => {
        return this.router.createUrlTree([
          'property-catalog',
          assetsData.parentInfo.objectId,
          assetsData.parentInfo.displayName,
          assetsData.assetInfo.type,
          assetsData.assetInfo.objectId,
          assetsData.assetInfo.displayName,
        ]);
      }),
      catchError((err) => {
        if (err instanceof NotFoundError) {
          this.notificationService.showErrorDialog('core.notification.notFoundAlreadyDeleted');
          return of(this.router.createUrlTree(['properties', 'propertylist', 'geography_filters']));
        } else {
          throw err;
        }
      })
    );
  }
}
