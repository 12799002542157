import { VideoEntity } from '../models';
import { MediaItem } from '../../../shared/domain/pictures/models/picture.interface';
import { DocumentEntity } from '../../documents/models/document.model';
import { LightBoxConfig } from '../../../shared/core';

export class VideoHelpers {
  static pictureToVideoEntity(picture: MediaItem): VideoEntity {
    return {
      sources: [
        {
          src: picture.href,
          media: '',
          type: picture.contentType,
        },
      ],
      img: picture.previewHref != picture.href ? picture.previewHref ?? '' : '',
    };
  }

  static documentToVideoEntity(document: DocumentEntity): VideoEntity {
    return {
      sources: [
        {
          src: document.href,
          media: '',
          type: document.file.contentType,
        },
      ],
      img: document.previewHref != document.href ? document.previewHref ?? '' : '',
    };
  }

  static lightboxConfigToVideoEntity(lightboxConfig: LightBoxConfig): VideoEntity {
    return {
      sources: [
        {
          src: lightboxConfig.imageUrl ?? '',
          media: '',
          type: lightboxConfig.contentType ?? '',
        },
      ],
      img: lightboxConfig.previewUrl != lightboxConfig.imageUrl ? lightboxConfig.previewUrl ?? '' : '',
    };
  }
}
