<nav mat-tab-nav-bar data-test-id="tabs-group" [tabPanel]="tabPanel">
  <ng-container *ngFor="let tab of tabs">
    <a
      *ngIf="!tab.hidden"
      mat-tab-link
      data-test-tab-link
      [disabled]="tab.disabled"
      [routerLink]="tab.type"
      [routerLinkActive]="'mdc-tab--active'"
      [routerLinkActiveOptions]="{ exact: routerLinkExact }"
      [attr.data-test-id]="'tab-' + tab.type"
    >
      <span>{{ tab.titleTranslationKey | translation }}</span>
    </a>
  </ng-container>
</nav>
