export class NotAuthorizedError extends Error {
  constructor(public originalError?: any) {
    super(originalError.message);
    this.name = NotAuthorizedError.name;
    Object.setPrototypeOf(this, NotAuthorizedError.prototype);
  }

  toString() {
    return this.originalError.message;
  }
}
