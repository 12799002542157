export enum FieldType {
  file = 'file',
  none = '',
  propertyAssetSelector = 'propertyAssetSelector',
  textarea = 'textarea',
  boolean = 'boolean',
  text = 'text',
  date = 'date',
  price = 'price',
  hidden = 'hidden',
  radio = 'radio',
  checkbox = 'checkbox',
  tag = 'tag',
  picture = 'picture',
  propertyFilter = 'propertyfilter',
  companyFilter = 'companyfilter',
}
