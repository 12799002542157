import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { PropertyAssetSelectorService } from '../services';
import { NotificationService } from '../../../../core/services/notification.service';
import { TreeNode } from '../../tree/models';
import { PropertyResourceListEntity } from '../models/property-asset-search.interafces';
import { MinimalAssetTreeNode } from '../../../../features/property-catalog/models/interfaces';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotFoundError } from '../../../../core/common';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-property-asset-selector',
  template: `
    <ng-container *ngIf="assetName; else propertyAssetSelector">
      <div class="selected-option">{{ assetName }}</div>
    </ng-container>

    <ng-template #propertyAssetSelector>
      <app-property-asset-selector
        [formControl]="formControl"
        [formlyAttributes]="field"
        [loading]="loading"
        [pending]="pending"
        [assetSelection]="field.props?.assetSelection"
        [properties]="properties$ | async"
        [treeDefinition]="treeDefinition$ | async"
        (search)="search($event)"
        (loadTree)="loadTree($event)"
      ></app-property-asset-selector>
      <formly-validation-message
        *ngIf="showError"
        [class.error]="showError"
        [field]="field"
      ></formly-validation-message>
    </ng-template>
  `,
  styles: [
    `
      .error {
        color: #c4401a;
      }

      .selected-option {
        margin: 0 0 20px 0;
        display: inline-block;
        background-color: #a3c3d4 !important;
        color: #000;
        border-radius: 3px;
        padding: 12px 8px;
      }
    `,
  ],
})
export class FormlyPropertyAssetSelectorComponent extends FieldType<FieldTypeConfig> implements OnInit {
  loading: boolean;
  pending: boolean;
  assetName: string;
  properties$: Observable<PropertyResourceListEntity[]>;
  treeDefinition$: Observable<TreeNode<MinimalAssetTreeNode, { label: string }>> | null;

  constructor(
    private notificationService: NotificationService,
    private propertyAssetSelectorService: PropertyAssetSelectorService
  ) {
    super();
  }

  ngOnInit() {
    const queryParam = this.field.props?.assetSelection?.queryParam;

    if (queryParam) {
      this.loadAssetInfoByObjectId(queryParam);
    }
  }

  public search(searchTerm: string): void {
    this.pending = true;
    this.properties$ = this.propertyAssetSelectorService
      .searchProperties(searchTerm)
      .pipe(tap({ next: (_) => (this.pending = false) }));
  }

  loadTree(objectId: string): void {
    if (objectId) {
      this.loading = true;
      this.treeDefinition$ = this.propertyAssetSelectorService.loadTreeByObjectId(objectId).pipe(
        tap({ next: (_) => (this.loading = false) }),
        catchError((error) => {
          if (error instanceof NotFoundError) {
            this.notificationService.showErrorDialog('forms.assetSelector.error.propertyNotFound');
            return EMPTY;
          } else {
            throw error;
          }
        })
      );
    } else {
      this.loading = false;
      this.treeDefinition$ = null;
    }
  }

  private loadAssetInfoByObjectId(objId: string): void {
    this.propertyAssetSelectorService
      .loadAssetInfoByObjectId(objId)
      .pipe(
        take(1),
        tap({ next: (asset: any) => (this.assetName = asset.displayName) }),
        catchError((error) => {
          this.value = [];
          if (error instanceof NotFoundError) {
            this.notificationService.showErrorDialog('forms.assetSelector.error.propertyNotFound');
            return EMPTY;
          } else {
            throw error;
          }
        })
      )
      .subscribe();
  }
}
