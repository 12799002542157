export enum FormlyFieldType {
  input = 'input',
  file = 'file',
  propertyAssetSelector = 'property-asset-selector',
  datepicker = 'datepicker',
  dateRangePicker = 'date-range-picker',
  textarea = 'custom-textarea',
  checkbox = 'checkbox',
  select = 'select',
  fileUploadStepper = 'file-upload-stepper',
  pictureAsset = 'picture-asset',
  button = 'button',
  autoComplete = 'autoComplete',
  textFilter = 'textFilter',
  email = 'email',
  radio = 'custom-radio',

  repeatSection = 'repeat-section',
}
