<div class="action-bar" data-test-action-bar>
  <div class="block" *ngFor="let buttonBlock of configSubject | async">
    <ak-spinner-button
      *ngFor="let button of buttonBlock"
      [color]="getColor(button)"
      [buttonClass]="getClass(button)"
      class="action-button"
      [disabled]="button.disabled === true"
      [svgIconName]="button.icon"
      [buttonText]="button.text"
      [inProgress]="button.inProgress"
      [attr.data-test-id]="button.testId"
      (clicked)="clicked.emit(button.value)"
    ></ak-spinner-button>
  </div>
</div>
