import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MenuModule, UserPictureModule } from '@akelius/angular-ui-kit';
import { CoreModule } from './core/core.module';
import { UiFormlyModule } from './shared/functional/ui-formly/ui-formly.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Params, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { StatusPagesModule } from './features/status-pages/status-pages.module';
import { MatMenuModule } from '@angular/material/menu';
import { ContentComponent } from './app/content/content.component';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbComponent } from './app/breadcrumb/breadcrumb.component';
import { BreadcrumbDisplayComponent } from './app/breadcrumb/breadcrumb-display.component';
import { TranslationModule } from './shared/functional/translation/translation.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SharedCoreModule } from './shared/core/shared-core.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    UserPictureModule,
    UiFormlyModule.forRoot(),
    AppRoutingModule,
    StatusPagesModule,
    // Components for content component
    MenuModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TranslationModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    SharedCoreModule,
  ],
  declarations: [AppComponent, ContentComponent, BreadcrumbComponent, BreadcrumbDisplayComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller, activatedRoute: ActivatedRoute) {
    const isScrollEvent = (event: Event): event is Scroll => {
      return event instanceof Scroll;
    };

    router.events.pipe(filter(isScrollEvent)).subscribe({
      next: (e: Scroll) => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {
            if (e?.position) {
              viewportScroller.scrollToPosition(e.position);
            }
          }, 0);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          if (!(e.routerEvent instanceof NavigationEnd)) {
            return;
          }
          if (this.getCombinedRouteData(activatedRoute.snapshot).maintainScrollPosition !== true) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
      },
    });
  }

  private getCombinedRouteData(snapshot: ActivatedRouteSnapshot): any {
    // Base case: if there's no child, return the current route's data
    if (!snapshot.firstChild) {
      return snapshot.data;
    }

    // Recursive case: merge current route's data with child's data
    const childData = this.getCombinedRouteData(snapshot.firstChild);
    return { ...childData, ...snapshot.data };
  }
}
