export class AppError extends Error {
  public userMessage;

  constructor(public originalError?: any, userMessage?: string) {
    super(originalError.message);
    this.name = AppError.name;
    Object.setPrototypeOf(this, AppError.prototype);

    if (userMessage) {
      this.userMessage = userMessage;
    }
  }

  toString() {
    return this.userMessage || this.originalError.message;
  }
}
