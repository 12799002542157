import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {FieldTypeConfig} from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import moment, { Moment } from 'moment';

@Component({
  selector: 'formly-field-date-range-picker',
  template: `
    <forms-date-range-picker
      [formControl]="formControl"
      [formlyAttributes]="field"
      [selectedValue]="selectedValue">
    </forms-date-range-picker>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyDateRangePickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  selectedValue: { begin: Moment; end: Moment | null } | null;

  ngOnInit() {
    this.selectedValue = this.getSelectedValue();
  }

  getSelectedValue(): { begin: Moment; end: Moment | null } | null {
    if (this.value) {
      const dates = this.value.split('~~');
      return {
        begin: moment(dates[0]),
        end: dates[1] ? moment(dates[1]) : null,
      };
    }
    return null;
  }
}
